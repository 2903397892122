/* global ui, dpdhl_settings */

(function ($) {
	$.widget('ipnp.equation', {
		resultFieldFirstInputFieldMap: {},
		options: {
			equation: '',
			mathround: false
		},

		_create() {
			ui.merge('ipnp.equation', this);
			this._initCalculation();
		},

		/**
		 * Initialize equation calculation.
		 * (1.) iterate over all forms
		 * (1.1.) search for input fields with class 'equation'
		 * (1.2.) determine inputFieldIds (e.g. {field1}) within the equation expression and set onBlur- resp. a custom onBlur-event
		 * to evaluate the equation.
		 */
		_initCalculation() {
			const _this = this;

			// Bind custom event to trigger a re-evaluation of all equations except the currently processed one.
			this.element.bind('reevaluateAll', (e, param, curResultFieldId) => {
				$.each(_this.resultFieldFirstInputFieldMap, (key, value) => {
					if (key !== curResultFieldId) {
						$(`#${value}`).trigger('customBlur');
					}
				});
			});

			// Find equations.
			this.element.find('.equation__input').each(() => {
				const regex = /{([^}]+)}/gi;
				let equation = '';
				const eqVal = _this.options.equation;
				if (eqVal) {
					equation = $.trim(eqVal).replace(/\s/g, '');
				}
				// Determine result field (e.g. equation equals {a}+{b}={result} the form id is 'result').
				if (equation.indexOf('=') !== -1) {
					const resultFieldId = equation.substring(equation.indexOf('=') + 2, equation.length - 1);
					const resultField = _this.element.find(`#${resultFieldId}`);
					try {
						if (resultField.length !== 0) {
							// Delete resultField information from equation.
							equation = equation.substring(0, equation.indexOf('='));
							// All involved form fields according to the given equation.
							const fieldArray = equation.match(regex);
							// Set onBlur-Listener.
							$.each(fieldArray, (index, item) => {
								const fieldId = item.substring(1, item.length - 1);
								const curField = _this.element.closest('form').find(`#${fieldId}`);
								_this._setEquationEventListener(
									curField,
									['blur', 'change'],
									equation,
									fieldArray,
									resultField,
									resultFieldId,
									true
								);
								// Trigger a re-evaluation.
								_this._setEquationEventListener(
									curField,
									['customBlur'],
									equation,
									fieldArray,
									resultField,
									resultFieldId,
									false
								);
								// Store (only the first) formField and corresponding resultField.
								if (!_this._contains(resultFieldId, _this.resultFieldFirstInputFieldMap)) {
									_this.resultFieldFirstInputFieldMap[resultFieldId] = fieldId;
								}
							});
							// Do initial calculation.
							_this._processEquation(equation, fieldArray, resultField);
						}
					} catch (typeError) {
						ui.log('error accessing resultField', typeError);
					}
				}
			});
		},

		/**
		 * Set event listeners to process the equation.
		 * @param curField The input field
		 * @param eventsMap The events to set
		 * @param equation The equation
		 * @param fieldArray The input field ids that are part of the equation
		 * @param resultField The result input field
		 */
		_setEquationEventListener(curField, eventsMap, equation, fieldArray, resultField) {
			$.each(eventsMap, (index, item) => {
				curField.bind(item, () => {
					this._processEquation(equation, fieldArray, resultField);
					return false;
				});
			});
		},

		_contains(key, map) {
			return map[key] !== undefined;
		},

		/**
		 * Process the equation, store result in given resultField and
		 * trigger a 'reevaluateAll'-event (see: method 'initCalculation').
		 */
		_processEquation(equation, fieldArray, resultField) {
			// eslint-disable-next-line no-eval
			let result = eval(this._getProcessingEquation(fieldArray, equation));
			if (!Number.isFinite(result)) {
				result = '';
			}
			resultField.val(this._formatEquationResult(result)).change();
		},

		/**
		 * Substitute placeholder within equation with real (valid) form values.
		 */
		_getProcessingEquation(fieldArray, equation) {
			const _this = this;
			let processedEq = equation;
			$.each(fieldArray, (index, item) => {
				const formId = item.substring(1, item.length - 1);
				let formValue = _this.element.closest('form').find(`#${formId}`).val();
				formValue = $.ipnp.form().makeParseableValue(formValue);
				if (Number.isNaN(parseFloat(formValue))) {
					formValue = 0;
				}
				processedEq = processedEq.replace(item, formValue);
			});
			return processedEq;
		},

		_formatEquationResult(aResult) {
			let result = aResult;
			let resultParts;
			if (result && result !== '') {
				if (this.options.mathround === true) {
					result = Math.round(result);
					return result;
				}
				result = result.toFixed(2);
				resultParts = result.split('.');

				// eslint-disable-next-line camelcase
				switch (dpdhl_settings.language) {
					case 'de':
						resultParts[0] = this._formatWithSeparator(resultParts[0], '.');
						result = `${resultParts[0]},${resultParts[1]}`;
						break;
					case 'en':
						resultParts[0] = this._formatWithSeparator(resultParts[0], ',');
						result = `${resultParts[0]}.${resultParts[1]}`;
						break;
					default:
						// Should never be reached! (Added 'default' case to fix SonarQube report.)
						return true;
				}
			}
			return result;
		},

		_formatWithSeparator(val, separator) {
			let i;
			let j = 0;
			let result = '';

			i = val.length - 1;
			while (i >= 0) {
				result = val.substring(i, i + 1) + result;
				// eslint-disable-next-line no-plusplus
				j++;
				if (j === 3 && i > 0) {
					result = separator + result;
					j = 0;
				}
				// eslint-disable-next-line no-plusplus
				i--;
			}
			return result;
		}
	});
}(ui.$));
