/* global ui, dpdhl_settings */

(function ($) {
	$.widget('ipnp.form', {

		/**
		 * Show forms onl where when JS is enabled.
		 * @private
		 */
		_create() {
			this.element.removeClass('d-none');
			this.element.attr('aria-hidden', 'false');
		},

		/**
		 * Prefill form data with URL parameter values.
		 * @public
		 */
		prefill() {
			new URLSearchParams(window.location.search).forEach((value, key) => {
				const element = document.getElementById(key);
				if (element) {
					if (!element.readOnly) {
						if (element.tagName === 'INPUT') {
							// Checkbox
							if (element.type === 'checkbox') {
								element.checked = (value !== 'false');
								// Date field
							} else if (element.dataset.role === 'date') {
								const prefilledDate = $.datepicker.parseDate($.datepicker.ISO_8601, value);
								if (prefilledDate) {
									$(element).datepicker(
										'setDate',
										$.datepicker.formatDate($(element).datepicker('option', 'dateFormat'), prefilledDate)
									);
								}
								// Text field
							} else {
								element.value = value;
							}

							// Text area
						} else if (element.tagName === 'TEXTAREA') {
							element.value = value;

							// Dropdown
						} else if ((element.tagName === 'SELECT') || (element.type === 'SELECT-MULTIPLE')) {
							for (const option of element.options) {
								if (option.value === value) {
									option.setAttribute('selected', '');
								} else {
									option.removeAttribute('selected');
								}
							}
						}
						$(element).trigger('change');
					}

					// Radio buttons where not identified by ID
				} else {
					const elements = document.getElementsByName(key);
					elements.forEach((element) => {
						if (element.tagName === 'INPUT') {
							element.checked = (element.value === value);
							$(element).trigger('change');
						}
					});
				}

				this._prefillPlaceholder(key, value);
			});
		},

		/**
		 * Prefill placeholder spans in form with URL parameter values.
		 * @param key The name of form element.
		 * @param value The value of param for form element.
		 * @private
		 */
		_prefillPlaceholder(key, value) {
			const placeholderTags = $(`form p[class=with-placeholder] span[data-placeholder='${key}']`);
			placeholderTags.text(value);
		},

		makeParseableValue(aVal) {
			let val = aVal;
			if (val && val !== '') {
				// eslint-disable-next-line camelcase
				switch (dpdhl_settings.language) {
					case 'de':
						val = val.replace(/\./g, '');
						val = val.replace(/,/g, '.');
						break;
					case 'en':
						val = val.replace(/,/g, '');
						break;
					default:
						// Should never be reached! (Added 'default' case to fix SonarQube report.)
						return true;
				}
			}
			return val;
		}
	});
}(ui.$));
