/* eslint-disable no-undef, no-param-reassign, prefer-spread */

(function ($, ui) {
	ui.lib = ui.lib || {};

	const uilib = {
		lib: {
			formValidate: null,
			currentBreakpoint: '',
			breakpointLimits: {
				sm: 768,
				md: 992,
				lg: 1200
			},

			/**
			 * Helper function to check if the current instance is the author instance.
			 *
			 * @returns {boolean} 'true' if the author instance, otherwise 'false'.
			 */
			isAuthorInstance() {
				return !!document.querySelector('body[data-authorinstance="true"]');
			},

			/**
			 * Helper function to check if edit mode is enabled.
			 *
			 * @returns {boolean} 'true' if edit mode is enabled, otherwise 'false'.
			 */
			isEditMode() {
				return !!document.querySelector('body[data-editmode="true"]');
			},

			/**
			 * Helper function to get current viewport.
			 *
			 * @returns {string} The current breakpoint, e.a. xs, sm, md, lg
			 */
			getBreakpoint() {
				uilib.lib.currentBreakpoint = window.getComputedStyle(document.body, ':before').content.replace(/"/g, '');
				return uilib.lib.currentBreakpoint;
			},

			/**
			 * Helper function check is client a mobile device.
			 *
			 * @returns {boolean}
			 */
			isMobileDevice() {
				let check = false;
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
					check = true;
				}
				return check;
			},

			/**
			 * Helper function to convert 'rem' values to pixels.
			 *
			 * @param rem The 'rem' value.
			 *
			 * @returns {number} The converted pixels value.
			 */
			convertRemToPixels(rem) {
				return rem * parseFloat(window.getComputedStyle(document.documentElement).fontSize);
			},

			/**
			 * Helper function to convert pixels to 'rem' values.
			 *
			 * @param px The pixels value.
			 *
			 * @returns {number} The converted 'rem' value.
			 */
			convertPixelsToRem(px) {
				return px / parseFloat(window.getComputedStyle(document.documentElement).fontSize);
			},

			/**
			 * Function to get the parameter value from current request URL for a given parameter name.
			 *
			 * @param {String} paramName - name of parameter which should be extracted/read from request URL.
			 *
			 * @returns {String} the parameter value or empty string if parameter not set in current request URL
			 */
			getParamValue(paramName) {
				const results = new RegExp(`[\?&]${paramName}=([^&#]*)`).exec(window.location.href);
				if (results) {
					return results[1] || '';
				}
				return null;
			},

			/**
			 * Function to get the current client
			 *
			 * @returns {String} the value of current client
			 */
			getCurrentTenant() {
				return document.getElementsByTagName('body')[0].getAttribute('data-ipnp-tenant');
			}
		}
	};

	$.extend(ui, uilib);
}($, ui));
