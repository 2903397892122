import 'jquery-validation';
import 'jquery-ui';
import '../../fragments/smart-banner/jquery-smart-banner/jquery-smart-banner';
import GLightbox from 'glightbox';

// Bootstrap Components
import {Collapse, Tooltip} from 'bootstrap';

// jQuery Widget Factory
import './ui.legacy/ui.bottom';
import './ui.legacy/ui.lib';
import './ui.legacy/ui.cookie';

// components for all tenants
import '../../pages/base-page/back-to-top/back-to-top';
import '../../pages/base-page/window';
import '../../fragments/infotext/infotext';
import '../../fragments/modal/modal';
import '../../fragments/form/form.validate';
import '../../fragments/datepicker/jquery.ui.datepicker-de';
import '../../fragments/form/input-group/input-group';
import '../../fragments/smart-banner/smart-banner';
import '../../components/accordion/accordion';
import '../../components/application/application';
import '../../components/application-accordion/application-accordion';
import '../../components/article/article';
import '../../components/faq/item/faq-item';
import '../../components/search/search';
import '../../components/search/autocomplete';
import '../../components/short-term-hint/short-term-hint';
import '../../components/tabs/tabs';
import '../../components/video/video';
import '../../components/form/form-container/form-container';
import '../../components/form/form-container/form-routing';
import '../../components/form/date-field/date-field';
import '../../components/form/text-field/text-field';
import '../../components/form/dropdown/dropdown';
import '../../components/form/placeholder/placeholder';
import '../../components/form/decision/decision';
import '../../components/form/equation/equation';
import '../../components/form/conditional-container/conditional-container';
import '../../components/form/upload/upload';
import '../../components/form/progress-bar/progress-bar';

// initialize jquery widgets
import './ui.legacy/ui.index';

window.Collapse = Collapse;
window.Tooltip = Tooltip;
window.GLightbox = GLightbox;
