/* global ui */

(function ($) {
	$.widget('ipnp.input-group', {
		options: {
			hoverClass: 'input-group--hover',
			focusVisibleClass: 'input-group--focus-visible',
			selectorClearButton: '.input-group__clear',
			selectorInput: '.input-group__input'
		},

		_create() {
			this._handleHover();
			this._handleFocusVisible();
			this._handleClearButton();
		},

		/**
		 * Detect if component is hovered and change component classes
		 *
		 * @private
		 */
		_handleHover() {
			this.element.hover(() => {
				this.element.addClass(this.options.hoverClass);
			}, () => {
				this.element.removeClass(this.options.hoverClass);
			});
		},

		/**
		 * Detect if component is focused and change component classes
		 *
		 * @private
		 */
		_handleFocusVisible() {
			// detect focus
			this.element.focusin(() => {
				this.element.addClass(this.options.focusVisibleClass);
			}).focusout(() => {
				this.element.removeClass(this.options.focusVisibleClass);
				this.element.removeClass('input-group--autocomplete');
			});

			// detect focus-visible
			ui.sub('user-is-tabbing', () => {
				if (this.element.find(this.options.selectorInput).hasClass('user-is-tabbing')) {
					this.element.addClass(this.options.focusVisibleClass);
				} else {
					this.element.removeClass(this.options.focusVisibleClass);
				}
			});
		},

		/**
		 * Sets listener for clear button and fades clear button in and out.
		 *
		 * @private
		 */
		_handleClearButton() {
			// clear input field
			this.element.find(this.options.selectorClearButton).on('click keydown', (e) => {
				if ((e.type === 'keydown' && e.keyCode === 13) || e.type === 'click') {
					e.preventDefault();
					const $input = this.element.find('input');
					$input.val('');
					this._toggleClearButton($input);
					$input.focus();
				}
			});

			// toggle show/hide initial
			this._toggleClearButton(this.element.find('input'));

			// toggle show/hide is input value changed
			this.element.find(this.options.selectorInput).on('keyup', (e) => {
				this._toggleClearButton($(e.currentTarget));
			});
		},

		/**
		 * Show or hides the clear button in dependency on input value
		 * @param {jQuery Object} $input input field as jQuery Object
		 * @private
		 */
		_toggleClearButton($input) {
			if ($input.val().length > 0) {
				this.element.find(this.options.selectorClearButton).show();
			} else {
				this.element.find(this.options.selectorClearButton).hide();
			}
		}
	});
}(ui.$));
