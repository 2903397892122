/* global ui, dpdhl_settings */
(function ($) {
	/**
	 * Widget Constructor
	 *
	 * @constructor
	 */
	$.widget('ipnp.search', {
		options: {
			queryTerm: null,
			start: 0,
			fuzziness: null,
			host: '',
			tenant: 'dhl',
			lowerRelevanceBound: 0,
			contentFieldsWeight: {
				content: 0,
				title: 0,
				description: 0,
				keywords: 0
			},
			faq: {
				maxFaqResults: 5,
				faqPosition: 3,
				faqFieldsWeight: {
					question: 0,
					answer: 0,
					keywords: 0
				}
			},
			ajaxData: {
				// indent json to make it human-readable
				indent: false,
				// general search algorithm
				defType: 'edismax',
				// how are search strings connected, if there are more than one string
				'q.op': 'AND',
				// order
				sort: 'score desc',
				// requested fields
				fl: 'content, title, description, answer, question, id, uri, tenant, score',
				// using the page property 'boost' as boost-factor
				bf: 'boost',
				// enable highlighting
				hl: true,
				// fields to highlight
				'hl.fl': 'content, title, description, question',
				// highlighting tag before
				'hl.tag.pre': '<strong>',
				// highlighting tag after
				'hl.tag.post': '</strong>'
			}
		},
		constants: {
			AJAX_URL: '/search/magnolia-collection/select?q=',
			FILTER_NAME_SEARCHQUERY: 'q',
			FILTER_NAME_START: 'start',
			RESULTS: 'results',
			NORESULTS: 'noresults',
			ERROR: 'error',
			SSR: 'ssr',
			MAX_RESULTS_INITIAL: 10
		},
		/** Cache for special search results */
		ssr: {
			regexConditions: [{
				conditionItems: [],
				resultItems: []
			}],
			stringListConditions: [{
				conditionItems: [],
				resultItems: []
			}]
		},

		/**
		 * Constructor
		 */
		_create() {
			ui.merge('ipnp.search', this);

			const _this = this;

			this.$resultsContainer = this.element.find('.searchresult__results');
			this.$moreButtonContainer = this.element.find('.searchresult__more');

			this._fetchAndSetFilterValues();

			this._requestSsr();

			/**
			 * EVENTS
			 */
			this.element.find('form').bind('submit', (e) => {
				ui.stop(e);
				if (_this._getInputValue()) {
					_this._resetFilter();
					_this._executeSearchRequest();
				}
			});

			this.$moreButtonContainer.find('button').bind('click', (e) => {
				e.preventDefault();
				$(e.currentTarget).blur();
				_this._handleMoreButton();
			});

			this._replaceTextContentPlaceholders();
		},

		/**
		 * Execute search request
		 */
		_executeSearchRequest() {
			const _this = this;

			this._setFilterValueQuery(_this._getInputValue());

			this._closeAutocompleteLayer();

			ui.log(`${this.widgetName} - Start search for ${this._getFilterValueQuery()}`);

			// match special search results
			const ssrMatched = this._matchSsr();
			// if no ssr matched -> request solr for general search results
			if (!ssrMatched) {

				// Calculate boost for content-pages
				_this._calcBoost(_this.options.contentFieldsWeight);

				this._requestSolr({
					itemType: 'website',
					onSuccess(response) {
						_this._cleanResultsView();
						_this._renderContentAndFaqItems(response);

						// INFO: Prevent focussing elements while clicking on them
						_this.element.find('a,button').unbind('mousedown');
						_this.element.find('a,button').mousedown((e) => {
							e.preventDefault();
						});
					}
				});
			}
		},

		/**
		 * Call ssr endpoint to get all configured special search results.
		 * @private
		 */
		_requestSsr() {
			const _this = this;
			const ipnpSection = document.getElementsByTagName('body')[0].getAttribute('data-ipnp-section');
			let ssrEndpointUrl = `/.ssr/?tenant=${ui.lib.getCurrentTenant()}&lang=${dpdhl_settings.language}`;
			if (ipnpSection) {
				ssrEndpointUrl += `&section=${ipnpSection}`;
			}
			fetch(
				ssrEndpointUrl,
				{
					method: 'GET'
				}
			).then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					return response.json();
				}
				ui.log(`Search xhr error status code ${response.status} while requesting ssr.`, this.widgetName);
				throw Error(response.statusText);
			})
				.then((data) => {
					_this.ssr = data;
					/**
					 * Initial Searchrequest if parameter was found
					 */
					if (_this._getFilterValueQuery()) {
						_this._fillInputField(_this._getFilterValueQuery());
						_this._executeSearchRequest();
					}
				})
				.catch((error) => {
					ui.log('Search xhr error while accessing received ssr response', error);
				});
		},

		/**
		 * Match special search result conditions with search query.
		 * @returns {boolean} true if a ssr condition matches, false otherwise
		 * @private
		 */
		_matchSsr() {
			// collect matching string list special search results
			const matchingStringListConditions = this.ssr.stringListConditions
				.filter((stringListCondition) => stringListCondition.conditionItems.indexOf(this._getFilterValueQuery()) > -1)
				.flatMap((condition) => condition.resultItems);
			// collect matching regex special search results
			const matchingRegexConditions = this.ssr.regexConditions
				.filter((regexCondition) => regexCondition.conditionItems.find(
					(conditionItem) => {
						try {
							return this._getFilterValueQuery().match(new RegExp(conditionItem, 'i'));
						} catch (e) {
							// ignore invalid regex
							ui.log(e);
							return false;
						}
					}
				))
				.flatMap((condition) => condition.resultItems);
			// if any ssr results were found -> append them to the result container
			if (matchingStringListConditions.length > 0 || matchingRegexConditions.length > 0) {
				this._cleanResultsView();
				this._handleFeedbackForUserAfterSearch(this.constants.SSR);
				matchingStringListConditions.concat(matchingRegexConditions).forEach((resultItem) => this._showSsrItem(resultItem));
				// prevent loading other search results
				return true;
			}
			return false;
		},

		/**
		 * Display special search result returned by url.
		 * @param ssrUrl url to the markup of the special search result
		 * @private
		 */
		_showSsrItem(ssrUrl) {
			const _this = this;
			fetch(
				ssrUrl,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				}
			).then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					return response.text();
				}
				ui.log(`Search xhr error status code ${response.status} while including ssr at ${ssrUrl}.`, this.widgetName);
				throw Error(response.statusText);
			})
				.then((responseBodyText) => {
					let ssrHtmlString = responseBodyText.toString().replaceAll('{search-term}', _this._getFilterValueQuery());
					if (ssrHtmlString.indexOf('{html-id}') > -1) {
						const resultElement = document.createElement('div');
						resultElement.innerHTML = ssrHtmlString;
						const resultElementId = resultElement.firstChild.id;
						ssrHtmlString = ssrHtmlString.replaceAll('{html-id}', resultElementId);
					}
					this.$resultsContainer.append(ssrHtmlString);
				})
				.catch((error) => {
					ui.log(`Search xhr error while accessing received ssr response from ${ssrUrl}`, error);
				});
		},

		/**
		 * Send request to solr with the given solr options
		 * @param solrOptions options to send to solr, contains search result item type and a callback method when response was received
		 *     sucessfully.
		 * @private
		 */
		_requestSolr(solrOptions) {
			if (!solrOptions.itemType) {
				ui.log('Cannot request solr search without item type!');
				return;
			}
			fetch(
				`${this.options.host + this.constants.AJAX_URL}${this._getFilterValueQueryEncoded()}&${
					new URLSearchParams(
						{
							...this.options.ajaxData,
							fq: `type:${solrOptions.itemType} AND (tenant:${this.options.tenant} OR partnerSearch:true) AND language:${dpdhl_settings.language}`,
							start: (solrOptions.itemType === 'faq' ? 0 : this._getFilterValueStart())
						}
					)}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				}
			).then((response) => {
				if (response.status >= 200 && response.status <= 299) {
					return response.json();
				}
				ui.log(`Search xhr error status code ${response.status}`, this.widgetName);
				throw Error(response.statusText);
			})
				.then((data) => {
					solrOptions.onSuccess(data);
				})
				.catch((error) => {
					this._cleanResultsView();
					this._handleFeedbackForUserAfterSearch(this.constants.ERROR);
					ui.log('Search xhr error', error);
				});
		},

		/**
		 * Get input value from searchfield
		 */
		_getInputValue() {
			return this.element.find('.input-group__input').val();
		},

		/**
		 * Fill input value for searchfield
		 *
		 * @param {string} inputValue value
		 */
		_fillInputField(inputValue) {
			this.element.find('.input-group__input').val(inputValue);
		},

		/**
		 * Show feedback/summary text (success or error hint) after search
		 *
		 * @param {string} status 			Ajax response status
		 * @param {int} totalResultsNumber 	total results of found elements
		 */
		_handleFeedbackForUserAfterSearch(status, totalResultsNumber) {
			// Render status to root-elem for CSS
			this.element.attr('data-status', status);
			let $slot = null;

			switch (status) {
				case this.constants.RESULTS:
					$slot = this.element.find('[data-text="results"]');
					$slot.find('[data-term]').text(this._getFilterValueQuery());
					$slot.find('[data-count]').text(totalResultsNumber);
					break;

				case this.constants.NORESULTS:
					this.element.find('[data-text="noresults"] [data-term]').text(this._getFilterValueQuery());
					break;

				case this.constants.ERROR:
					// nothing to replace. Text is not dynamic
					break;
				default:
					// Should never be reached! (Added 'default' case to fix SonarQube report.)
					ui.log(`${this.widgetName} - Operator ${status} not allowed.`);
			}
		},

		/**
		 * Replace placeholder in content templates
		 */
		_replaceTextContentPlaceholders() {
			$.each(this.element.find('[data-text]'), function () {
				let str = $(this).html();
				str = str
					.replace('{search-term}', '<span data-term=""></span>')
					.replace('{count}', '<span data-count=""></span>');
				$(this).html(str);
			});
		},

		/**
		 * Set query value
		 *
		 * @param {string} query search query value
		 */
		_setFilterValueQuery(query) {
			this.options.queryTerm = query;
		},

		/**
		 * Get query value
		 */
		_getFilterValueQuery() {
			return this.options.queryTerm;
		},

		/**
		 * Get the query value URL encoced including optional fuzziness variant.
		 */
		_getFilterValueQueryEncoded() {
			const specialChars = /([+\-!\(\)\{\}\[\]\^\"\~\*\?\:\/]|\&\&|\|\|)/;
			const queryTerm = this._getFilterValueQuery().replace(specialChars, '\\$1');
			if (this.options.fuzziness > 0) {
				// notice, that fuzzyness does not work on synonyms
				return encodeURIComponent(`"${queryTerm}" OR "${queryTerm}"~${this.options.fuzziness}`);
			}
			return encodeURIComponent(queryTerm);
		},

		/**
		 * Set start value
		 *
		 * @param {int} number start-value for search
		 */
		_setFilterValueStart(number) {
			this.options.start = number;
		},

		/**
		 * Get start value
		 */
		_getFilterValueStart() {
			return this.options.start || 0;
		},

		/**
		 * Fetch the filter-status for a further local processing.
		 * Url-params overwrites config-params
		 */
		_fetchAndSetFilterValues() {
			// QUERY
			let query = ui.getURLParameter(this.constants.FILTER_NAME_SEARCHQUERY);
			if (query) {
				query = decodeURIComponent(query.replace(/\+/g, ' '));
			}
			this._setFilterValueQuery(query);

			// START
			const start = parseInt(ui.getURLParameter(this.constants.FILTER_NAME_START), 10) || 0;
			this._setFilterValueStart(start);
		},

		/**
		 * Reset Filter
		 */
		_resetFilter() {
			this._setFilterValueStart(0);
		},

		/**
		 * Calculate boost for different fields.
		 * If boost is set to '-1' this means 'default', the default values are used.
		 *
		 * @param {string} boostValues values to boost search query
		 */
		_calcBoost(boostValues) {
			const defaultBoost = {
				// page
				content: 3,
				title: 7,
				description: 5,
				// faq
				question: 5,
				answer: 7,
				// both
				keywords: 12
			};

			let boostedFields = '';
			Object.keys(boostValues).forEach((key) => {
				if (boostValues[key] === -1) {
					boostValues[key] = defaultBoost[key];
				}
				boostedFields = `${boostedFields + key}^${boostValues[key]} `;
			});
			// Parameter 'qf' boosts fields for search of single words
			this.options.ajaxData = { ...this.options.ajaxData, ...{ qf: boostedFields } };
			// Parameter 'pf' boosts fields for search of text phrases
			this.options.ajaxData = { ...this.options.ajaxData, ...{ pf: boostedFields } };
		},

		/**
		 * Render content and FAQs.
		 *
		 * @param {json} result json result with found values
		 */
		_renderContentAndFaqItems(result) {
			const _this = this;
			let totalResultsNumber = this._showContentItems(result);
			this._setFilterValueStart(result.response?.docs?.length);

			// Calculate boost for faq-items
			this._calcBoost(_this.options.faq.faqFieldsWeight);

			this._requestSolr({
				itemType: 'faq',
				onSuccess(response) {
					// add FAQ results
					totalResultsNumber = _this._getTotalResultsNumber(response.response) + totalResultsNumber;
					_this._showFaqItems(response);
					// handle faq and user feedback
					if (totalResultsNumber > 0) {
						_this._handleFeedbackForUserAfterSearch(_this.constants.RESULTS, totalResultsNumber);
					} else {
						// no search hits found
						_this._handleFeedbackForUserAfterSearch(_this.constants.NORESULTS);
					}
				}
			});
			// handle content and user feedback
			if (totalResultsNumber > 0) {
				this._handleFeedbackForUserAfterSearch(this.constants.RESULTS, totalResultsNumber);
			} else {
				// no search hits found
				this._handleFeedbackForUserAfterSearch(this.constants.NORESULTS);
			}

			this._handleVisibilityOfMoreButton(result.response.numFound);
		},

		_closeAutocompleteLayer() {
			if (this.element.data('ui-autocomplete') !== undefined) {
				this.element.autocomplete('close');
			}
		},

		/**
		 * Show content if it exists.
		 *
		 * @param {json} result json result with found values
		 */
		_showContentItems(result) {
			const { docs } = result.response;
			let count = 0;
			if (docs && docs.length > 0) {
				// get template
				const contentTemplate = this.element.find('template.search-content__template')[0].content;
				const _this = this;
				docs.forEach((doc) => {
					if (doc.score > _this.options.lowerRelevanceBound) {
						// clone template
						const $content = $(contentTemplate).clone();
						const { title } = result.highlighting[doc.id];
						const { content } = result.highlighting[doc.id];
						const { description } = result.highlighting[doc.id];
						// replace content inside cloned template
						$content.find('.searchresult-item__title').html(title || doc.title);
						$content.find('.searchresult-item__text').html(this._checkForNoContent(description)
																	|| this._checkForNoContent(doc.description)
																	|| this._checkForNoContent(content)
																	|| doc.content);
						// show partner search results
						if (doc.tenant !== _this.options.tenant) {
							$content.find('.searchresult-item__partnerLink').removeClass('d-none').html(`<i>${doc.uri}</i>`);
						}
						$content.find('.searchresult-item')[0].setAttribute('href', `${doc.uri}`);
						_this.$resultsContainer.append($content);
						count++;
					}
				});
			}
			return count;
		},

		/**
		 * Check if the value equals (string) or contains (array) the string
		 * 'nocontentforthisproperty', which represents an empty SOLR-field.
		 *
		 * @param {string|Array<string>} value String or string array to check
		 * @return {null|string|Array<string>} Null if value contains 'nocontentforthisproperty', value if not
		 */
		_checkForNoContent(value) {
			if (Array.isArray(value)) {
				for (let i = 0; i < value.length; i++) {
					if (value[i] === "nocontentforthisproperty") {
						return null;
					}
				}
				return value;
			} else if (value === "nocontentforthisproperty") {
				return null;
			} else {
				return value;
			}
		},

		/**
		 * Show faq if it exists.
		 *
		 * @param {json} result json result with found values
		 */
		_showFaqItems(result) {
			const faqItems = result?.response?.docs;
			if (faqItems && faqItems.length > 0) {
				// move accordion container to result Container
				const faqResultTemplate = this.element.find('template.search-faq__template')[0]?.content;
				if (!faqResultTemplate) {
					ui.log('Could not find template for faq search result!');
					return;
				}
				const faqResultItem = faqResultTemplate.querySelectorAll('.search-faq-item')[0]?.cloneNode(true);
				// insert faq container to the correct position
				if (this.$resultsContainer.children().length < this.options.faq.faqPosition) {
					this.$resultsContainer.append(faqResultItem);
				} else {
					this.$resultsContainer[0].insertBefore(
						faqResultItem,
						this.$resultsContainer.children().get(this.options.faq.faqPosition)
					);
				}

				const faqContainer = faqResultItem.querySelectorAll('.accordion.faq')[0];
				// empty faq container dummy item
				faqContainer.innerHTML = '';
				// create faq item template to duplicate it for every faq search result
				const faqItemTemplate = faqResultTemplate.querySelectorAll('.accordion-item')[0]?.cloneNode(true);
				faqItems.slice(0, this.options.faq.maxFaqResults).forEach((faqResponseItem) => {
					if (faqResponseItem.answer) {
						// get accordion body of faq search result item
						const faqResponseAnswerElement = document.createElement('faqAnswerDummy');
						faqResponseAnswerElement.innerHTML = faqResponseItem.answer;
						const faqItemHtmlString = faqResponseAnswerElement.getElementsByClassName('accordion-item')[0].innerHTML;
						// clone accordion item from template and insert it to search result faq container
						const currentFaqItem = faqItemTemplate.cloneNode(true);
						faqContainer.append(currentFaqItem);
						// set head and body of faq search result item
						currentFaqItem.innerHTML = faqItemHtmlString;
					} else {
						ui.log('FAQ search response item has no question or no answer.', faqResponseItem);
					}
				});

				// initialize accordion and videos in faq
				ui.widgets.accordion.init();
			} else {
				ui.log('no faq items found');
			}
		},

		/**
		 * Clean view.
		 */
		_cleanResultsView() {
			this.$resultsContainer.html('');
			this._deactivateMoreButton();
		},

		/**
		 * Handle visibility of more-button.
		 * Depends on number of found search results
		 *
		 * @param {int} totalResultsNumber number of found search results
		 */
		_handleVisibilityOfMoreButton(totalResultsNumber) {
			if (this._getFilterValueStart() < totalResultsNumber && totalResultsNumber > this.constants.MAX_RESULTS_INITIAL) {
				this._activateMoreButton();
			} else {
				this._deactivateMoreButton();
			}
		},

		/**
		 * Show more-button.
		 */
		_activateMoreButton() {
			this.$moreButtonContainer.attr('data-more-results-possible', 'true');
		},

		/**
		 * Hide more-button.
		 */
		_deactivateMoreButton() {
			this.$moreButtonContainer.attr('data-more-results-possible', 'false');
		},

		/**
		 * 'Show more search results'-button is clicked -> load more results and display them
		 */
		_handleMoreButton() {
			const _this = this;

			// Calculate boost for content-pages
			_this._calcBoost(_this.options.contentFieldsWeight);

			this._requestSolr({
				itemType: 'website',
				onSuccess(result) {
					_this._showContentItems(result);
					_this._setFilterValueStart(_this._getFilterValueStart() + (result.response?.docs?.length || 0));
					_this._handleVisibilityOfMoreButton(result.response?.numFound);
				}
			});
		},

		/**
		 * Get the number of all result-items
		 *
		 * @param {json} result json with search result
		 */
		_getTotalResultsNumber(result) {
			return result.numFound;
		}
	});
}(ui.$));
