/* global ui */

(function ($) {
	$.widget('ipnp.back-to-top', {

		options: {
			visibleDelay: 3500,
			timeoutFadeIn: 300,
			timeoutFadeOut: 300
		},

		_create() {
			const _this = this;
			let fadeOutTimeout;

			ui.sub('ui.scroll', () => {
				// no to top button, when stop scrolling
				if (typeof fadeOutTimeout !== 'undefined') {
					clearTimeout(fadeOutTimeout);
				}

				// to top button fades out when scrolling stopped for "options.visibleDelay" milliseconds
				fadeOutTimeout = setTimeout(() => {
					_this.element.fadeOut(_this.options.timeoutFadeOut);
				}, _this.options.visibleDelay);

				// to top button fades in when scrolling down
				if (window.scrollY > 0) {
					_this.element.fadeIn(_this.options.timeoutFadeIn).css('display', 'inline-flex');
				} else {
					_this.element.fadeOut(_this.options.timeoutFadeOut);
				}
			});
		}
	});
}(ui.$));
