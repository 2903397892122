/* eslint-disable */
/* global console, ui */

(function ($, ui) {
	const uibottom = {

		/**
		 * register widgets
		 * @param {Object} [config] - configuration object
		 */
		register(config) {
			ui.log('REGISTER', config);
			if (!ui.widgets[config.widget]) {
				ui.widgets[config.widget] = {};
			}
			if (!config.sel) {
				ui.widgets[config.widget].singleton = true;
			}
			// mark singleton widgets (needed in ui.merge)
			ui.widgets[config.widget].order = config.order ? config.order : 1; // set init order
			ui.widgets[config.widget].name = config.widget; // save name
			ui.widgets[config.widget].async = config.async; // can the widget be inserted dynamically
			// create init function from config object
			ui.widgets[config.widget].init = function () {
				ui.widgets[config.widget].sel = config.sel ? $(config.sel) : ui.widget;
				return ui.widgets[config.widget].sel[config.widget](config.options || {});
			};
		},

		/**
		 * init widgets
		 * @param {Object} [config] - configuration object
		 */
		init(config) {
			// get current wcm mode - set in cond - fire events
			//   dhl.lib.logWcmMode();
			// call setup to configure ui once
			if (!ui.config.setup) {
				ui.config.setup = true;
				ui.setup();
			}
			// init only widgets in array
			if (typeof config === 'object' && $.isArray(config)) {
				$.each(config, (key, widget) => {
					if ($.inArray(widget, ui.widgets)) {
						if (ui.widgets[widget].init) {
							try {
								ui.log('INIT', widget);
								ui.widgets[widget].instances = ui.widgets[widget].init(); // save widget instance
							} catch (ex) {
								ui.log('ERROR', widget);
								ui.log(ex.stack);
							}
						}
					}
				});
			}
			// init all widgets
			else {
				const widgets = [];
				$.each(ui.widgets, (key, widget) => {
					widgets.push(widget); // make a sortable array from object
				});
				widgets.sort((a, b) => parseInt(a.order) - parseInt(b.order) // init order
				);
				$.each(widgets.reverse(), (key, widget) => {
					if (widget.init) {
						try {
							ui.log('INIT', widget.name);
							ui.widgets[widget.name].instances = widget.init(); // save widget instance
						} catch (ex) {
							ui.log('ERROR', widget.name);
							ui.log(ex.stack);
						}
					}
				});
			}
			let focussedElem = [];
			// INFO: "Barrierefreiheit/Accessibility": Prevent focussing elements while clicking on them
			window.addEventListener('keydown', (e) => {
				if (e.keyCode === 9) {
					focussedElem = $(':focus');
					focussedElem.removeClass('user-is-tabbing');
				}
			});
			window.addEventListener('keyup', (e) => {
				if (e.keyCode === 9) {
					focussedElem = $(':focus');
					focussedElem.addClass('user-is-tabbing');
					// fire event that identifies if the user is tabbing to trigger special handlings
					ui.pub('user-is-tabbing');
				}
			});
			document.addEventListener('click', () => {
				if (focussedElem.length > 0) {
					focussedElem.removeClass('user-is-tabbing');
				}
			});
			ui.log('INIT READY', ui);
		},

		/**
		 * provide backend config and data attribute - merge in widget.options
		 * @param {String} widget - name of widget
		 * @param {Object} instance - current widget object
		 * @param {Object} provider - jquery object with additional data attributes
		 */
		merge(widget, instance, provider) {
			const conf = instance.element;
			const id = conf.attr('id');
			if (!ui.widgets[widget].config) {
				ui.widgets[widget].config = {};
			}
			instance.options = $.widget.extend(
				{},
				instance.options || {},
				ui.widgets[widget].config.default || {},
				ui.widgets[widget].config[id] || {}
				//! ui.widgets[widget].singleton ? conf.data() || {} : {}
			);
			if (!ui.widgets[widget].singleton) {
				const data = {};
				// dont merge jquery widget instance data - saved on same node
				$.each(conf.data(), (k, v) => {
					if (!v.eventNamespace && !v.uuid) {
						data[k] = v;
					}
				});
				$.extend(instance.options, data);
			}
			// provide more options from other elements than the widget root
			if (provider) {
				$.extend(instance.options, provider.data());
			}
			// ui.log(widget, instance.options);
			return instance.options;
		},

		/**
		 * get all config from registered widgets
		 * @param {String} widget - name of widget
		 */
		get(widget) {
			return (ui.widgets[widget]) ? ui.widgets[widget].config : {};
		},

		/**
		 * stop events
		 * @param {Event} e - event object
		 */
		stop(e) {
			e.preventDefault();
			e.stopPropagation();
		},
		/**
		 * console wrapper for safety debuging
		 * @param {String|Object} key
		 * @param {String|Object} value
		 * @param {Bool} interval
		 */
		log(key, value, interval) {
			if (ui.config.log === true) {
				value = (value) || '';
				if (typeof console === 'object') {
					console.log(key, value);
					if (interval) {
						ui.interval = window.setInterval(() => {
							console.log(key, value);
						}, 1000);
					}
				}
			}
		},

		/**
		 * subscribe
		 * @param {String} event
		 * @param {Function} the handler function to execute if event is published
		 */
		sub() {
			ui.log('SUB', arguments[0]);
			if (arguments.length > 1) {
				ui.log('SUB', arguments[1]);
			}
			ui.widget.on.apply(ui.widget, arguments);
		},

		/**
		 * unsubscribe
		 * @param {String} event
		 * @param {Function} [fn] the function to unsubscribe - leave empty to unsubscribe all handler
		 */
		unsub() {
			ui.log('UNSUB', arguments[0]);
			ui.widget.off.apply(ui.widget, arguments);
		},

		/**
		 * publish
		 * @param {String} event
		 * @param {Object} data
		 */
		pub() {
			ui.log('PUB', arguments[0]);
			if (arguments.length > 1) {
				ui.log('PUB', arguments[1]);
			}
			ui.widget.trigger.apply(ui.widget, arguments);
		},

		/**
		 * returns true if given widget is the last widget instance
		 * @param {Object} widget - widget instance
		 */
		last(widget) {
			return (ui.widgets[widget.widgetName].sel.last().get(0) === widget.element.get(0));
		},

		/**
		 * returns true if given widget is the first widget instance
		 * @param {Object} widget - widget instance
		 */
		first(widget) {
			return (ui.widgets[widget.widgetName].sel.first().get(0) === widget.element.get(0));
		},

		/**
		 * return the current device group - smartphone : tablet : desktop
		 */
		device() {
			return $('html').css('content');
		},

		/**
		 * return a html escaped string
		 * @param {String} str - user input
		 */
		escape(str) {
			const div = document.createElement('div');
			div.appendChild(document.createTextNode(str));
			return div.innerHTML;
		},

		/**
		 * simple hashCode implementation
		 * @param {String} str - string to calculate hash for
		 */
		hashCode(s) {
			let h = 0;
			const l = s.length;
			let
				i = 0;
			if (l > 0) {
				while (i < l) {
					h = (h << 5) - h + s.charCodeAt(i++) | 0;
				}
			}
			return h;
		},

		/**
		 * Delivers the url parameter with the specified name
		 * @param {String} name of the url parameter
		 */
		getURLParameter(name) {
			return decodeURIComponent((new RegExp(`[?|&]${name}=` + '([^&;]+?)(&|#|;|$)')
				.exec(window.location.search) || ['', ''])[1].replace(/\+/g, '%20')) || null;
		},

		/**
		 * used for non dom based widgets and to trigger pub/sub events
		 * call non dom based widgets via ui.widget.data('ui-widgetname').yourPulicFunction();
		 */
		widget: $({}),

		/**
		 * save jquery reference
		 */
		$,

		/**
		 * placeholder - called in ui.init
		 */
		setup() {
			// enable logger via url param log=true
			if (window.location.search.indexOf('log=true') !== -1) {
				ui.config.log = true;
			}
			if (window.location.search.indexOf('debug=true') !== -1) {
				ui.config.log2 = true;
			}
		}
	};

	$.extend(ui, uibottom);
}($, ui));
