/* global ui */

(function ($) {
	$.widget('ipnp.tabs', {

		options: {
			activeItemClass: 'nav-item--active'
		},
		_create() {
			this._onBreakpointChanged();
			if (ui.lib.getBreakpoint() !== 'xs') {
				this._setActiveItem();
			}
			if (ui.lib.isEditMode()) {
				setTimeout(() => {
					document.querySelectorAll('a[data-bs-toggle="tab"]').forEach((tabLink) => {
						tabLink.classList.remove('disabled');
					});
				}, '1000');
			}
		},

		/**
		 * Initialize selector after breakpoint changed
		 */
		_onBreakpointChanged() {
			const _this = this;
			ui.sub('ui.breakpointChanged', (e, data) => {
				if (data !== 'xs') {
					_this._setActiveItem();
				}
			});
		},

		/**
		 * Sets specific class to active tab item
		 */
		_setActiveItem() {
			// enable anchor to tab
			this._anchorToTab();

			window.onhashchange = () => {
				this._anchorToTab();
			};

			this.element.find('a[data-bs-toggle="tab"]').on('shown.bs.tab', (e) => {
				const $target = $(e.target);
				this.element.find('a[data-bs-toggle="tab"]').parent().removeClass(this.options.activeItemClass);
				$target.parent().addClass(this.options.activeItemClass);
			});
		},

		_anchorToTab() {
			const { hash } = document.location;
			const anchor = this.element.find(`.nav-tabs a[href="${hash}"]`);
			if(anchor.length > 0){
				const anchorTop = anchor.offset().top;
				anchor.tab('show');
				$('html, body').animate({ scrollTop: anchorTop }, 1500, 'easeInSine');
			}
		}
	});
}(ui.$));
