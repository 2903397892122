/* global ui */

(function ($) {
	$.widget('ipnp.smart-banner', {

		options: {
			showBanner: true,
			requestParams: '',
			icon: '',
			linkText: 'Anzeigen',
			deeplink: '',
			daysHidden: 30,
			bannertext: ''
		},

		_create() {
			ui.merge('ipnp.smart-banner', this);
			ui.sub('showSmartbanner', this._show());
			if (this.options.showBanner) {
				ui.pub('showSmartbanner');
			}
		},

		/**
		 * function create the complete deeplink
		 * the URL is composed of various properties and parameters
		 */
		_createDeeplink() {
			let deeplink = '';
			const url = this.options.deeplink;
			if (url) {
				const currentUrlParams = window.location.search;
				deeplink =
					`${url}?campaign_params&app_params${currentUrlParams.replace('?', '&')}&${this.options.requestParams.replace('?', '')}`;
			}
			return deeplink;
		},

		/**
		 * function calls smartbanner plugin with properties
		 * banner is only shown, if a deeplink ist edited
		 */
		_show() {
			const deeplink = this._createDeeplink();
			if (deeplink) {
				$.smartbanner({
					text: this.options.bannertext,
					icon: this.options.icon,
					button: this.options.linkText,
					url: deeplink,
					daysHidden: this.options.daysHidden,
					buttonClass: 'btn btn--primary btn--medium',
					appendToSelector: '.smart-banner__wrapper'
				});
			}
		}
	});
}(ui.$));
