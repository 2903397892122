/* global ui */
(function ($) {
	$.widget('ipnp.short-term-hint', {
		_create() {
			const { shortTermHintUrl } = this.element[0].dataset;
			if (shortTermHintUrl) {
				fetch(
					shortTermHintUrl,
					{
						method: 'GET',
						headers: {
							'Content-Type': 'application/json'
						}
					}
				).then((response) => {
					if (response.status >= 200 && response.status <= 299) {
						return response.text();
					}
					ui.log(`Error code ${response.status} while requesting short term hint at ${shortTermHintUrl}.`, this.widgetName);
					throw Error(response.statusText);
				})
					.then((responseBodyText) => {
						this.element[0].outerHTML = responseBodyText.toString();
					})
					.catch((error) => {
						ui.log(`Error while accessing short term hint response from ${shortTermHintUrl}.`, error);
					});
			}
		}
	});
}(ui.$));
