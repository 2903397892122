/* global ui */

(function ($) {
	$.widget('ipnp.text-field', {
		options: {
			focusVisibleClass: 'text-field--focus-visible',
			selectorInput: '.text-field__input, .text-field__textarea'
		},

		_create() {
			const _this = this;

			// detect focus-visible
			ui.sub('user-is-tabbing', () => {
				if (_this.element.find(_this.options.selectorInput).hasClass('user-is-tabbing')) {
					_this.element.addClass(_this.options.focusVisibleClass);
				} else {
					_this.element.removeClass(_this.options.focusVisibleClass);
				}
			});
		}
	});
}(ui.$));
