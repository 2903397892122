/* global ui */

(function ($) {
	$.widget('ipnp.placeholder', {

		_create() {
			this._initPlaceholder(this.element);
		},

		/**
		 * search for placeholder in form text content.
		 */
		_initPlaceholder($form) {
			// initial replace
			this._replaceText($form, (placeholder) => {
				const key = placeholder.substring(1, placeholder.length - 1);
				const element = $form.find(`[name="${key}"]`);
				let value = element.val();

				// add change event listener for further changes on placeholder reference elements only for datepicker hidden field
				ui.sub('datepicker.change', (e, data) => {
					$form.find(`span[data-placeholder="${data.name}"]`).each((_index, aElement) => {
						this._replaceElementOnChange(aElement, data.date);
					});
				});

				// add change event listener for further changes on placeholder reference elements but not for datepicker hidden field
				element.on('change', (event) => {
					// hide placeholder if empty
					$form.find(`span[data-placeholder="${key}"]`).each((_index, aElement) => {
						this._replaceElementOnChange(aElement, event.target.value);
					});
				});

				// replace the placeholder
				const spanPlaceholder = document.createElement('span');
				spanPlaceholder.setAttribute('data-placeholder', key);
				if (value !== undefined) {
					// use formatted date of datefield input and not datefield hidden value
					if (element.prev('.date-field__input').length > 0) {
						value = element.prev('.date-field__input').val();
					}
					spanPlaceholder.appendChild(document.createTextNode(decodeURIComponent(value)));
				} else {
					spanPlaceholder.appendChild(document.createTextNode(''));
				}
				return spanPlaceholder.outerHTML;
			});

			// publish event after the form has been replaced to initialize the event listeners of the form component afterwards
			ui.pub('ui.form.ready', {
				form: $form
			});

			// hide all empty placeholders after initial replacement
			const $pTags = $form.find('span[data-placeholder]').parent('p');
			$pTags.each((index, pTag) => {
				pTag.classList.add('with-placeholder');
				if (!pTag.outerText) {
					pTag.hidden = true;
				}
			});
		},

		/**
		 * Replace element with placeholder on change
		 *
		 * @param element element
		 * @param targetValue replacement string
		 */
		_replaceElementOnChange(element, targetValue) {
			const targetElement = $(element);
			let text = targetValue.replace(/\+/g, ' ');
			if (text && text.trim() !== '') {
				text = text.replace(/&amp;/g, '&');
				const parentElement = targetElement.parent('p');
				if (targetElement.length) {
					targetElement.text(text);
					targetElement.attr('hidden', false);
				}
				if (parentElement.length) {
					parentElement.attr('hidden', false);
				}
			} else if (targetElement.length) {
				targetElement.text('');
				targetElement.attr('hidden', true);
			}
		},

		/**
		 * Replace placeholders like <code>{myParam}</code> in form containers.
		 *
		 * @param $form form selector
		 * @param replace replacement string or a function
		 * @see https://github.com/cowboy/jquery-replacetext/blob/master/jquery.ba-replacetext.js
		 */
		_replaceText($form, replace) {
			const search = /({[a-z][^}]*})/gi;
			return $form.find('*').not('script').each((_index, element) => {
				let node = element.firstChild;
				let val;
				let new_val;

				// Elements to be removed at the end.
				const remove = [];

				// Only continue if firstChild exists.
				if (node) {
					// Loop over all childNodes.
					do {
						// Only process text nodes.
						if (node.nodeType === 3) {
							// The original node value.
							val = node.nodeValue;

							// The new value.
							new_val = val.replace(search, replace);

							// Only replace text if the new value is actually different!
							if (new_val !== val) {
								if (/</.test(new_val)) {
									// The new value contains HTML, set it in a slower but far more
									// robust way.
									$(node).before(new_val);

									// Don't remove the node yet, or the loop will lose its place.
									remove.push(node);
								} else {
									// The new value contains no HTML, so it can be set in this
									// very fast, simple way.
									node.nodeValue = new_val;
								}
							}
						}
					} while (node = node.nextSibling);
				}

				// Time to remove those elements!
				remove.length && $(remove).remove();
			});
		}
	});
}(ui.$));
