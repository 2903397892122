/* global ui, GLightbox */

(function ($) {
	$.widget('ipnp.Modal', {

		options: {
			skin: 'light',
			selector: '',
			width: '100vw',
			height: '100vh',
			closeOnOutsideClick: true
		},

		_create() {
			this._initAjaxModal();
		},

		/**
		 * Initialize GLightbox as modal
		 *
		 * @param options modal options to configure glightbox
		 * @param onOpen function after glightbox is open
		 * @returns {GLightbox} object
		 * @public
		 */
		initModal(options, onOpen) {
			const lightbox = GLightbox(options);

			if (onOpen !== undefined) {
				lightbox.on('open', () => {
					onOpen();
				});
			}

			ui.sub('ui.modal.close', () => {
				lightbox.close();
			});

			ui.sub('ui.modal.destroy', () => {
				lightbox.destroy();
			});

			return lightbox;
		},

		/**
		 * Initializes a Glightbox modal whose content is loaded via ajax
		 *
		 * @private
		 */
		_initAjaxModal() {
			const _this = this;
			if (this.element.filter('.modal').length > 0) {
				this.element.filter('.modal')[0].addEventListener('click', (e) => {
					e.preventDefault();
					const { href } = e.currentTarget;
					let className = 'modal--ajax-fullscreen';
					if (e.target.classList.contains('modal--ajax')) {
						className = 'modal--ajax';
					}

					const customSlideHTML = `<div class="gslide">
						<div class="gslide-inner-content">
							<div class="ginner-container">
								<div class="gslide-media">
									<div class="close-button">
										<button class="gclose gbtn" tabindex="2" aria-label="Close"></button>
									</div>
								</div>
							</div>
						</div>
					</div>`;

					const modalOptions = {
						...this.options,
						slideHTML: customSlideHTML
					};

					const onOpen = function () {
						_this._addClassesOnOpen(className);
						// moves the button so that it also scrolls when the content is scrolled
						$('#glightbox-body .close-button').prependTo('#glightbox-body .ginlined-content');
					};
					const lightbox = this.initModal(modalOptions, onOpen);
					this._fetchUrl(href).then((response) => {
						lightbox.setElements([
							{
								content: response
							}
						]);
						lightbox.open();

						// click close button
						document.querySelector('.gclose').addEventListener('click', (e) => {
							lightbox.close();
						});
					});
				});
			}
		},

		/**
		 * Initializes a dialog whose content is an HTML element on the page
		 *
		 * @public
		 */
		showHtmlModal(modalBodyClass, onOpenFunc, closeOnOutsideClick) {
			const _this = this;

			const onOpen = function () {
				_this._addClassesOnOpen('modal--ajax-fullscreen');
				_this._addClassesOnOpen('modal--html');

				if (closeOnOutsideClick) {
					_this._removeMobileClass();
					_this._addClassesOnOpen('modal--close-on-outside');
				}

				if (modalBodyClass && modalBodyClass !== '') {
					_this._addClassesOnOpen(modalBodyClass);
				}

				if (onOpenFunc) {
					onOpenFunc();
				}
			};

			const lightbox = this.initModal({
				...this.options
			}, onOpen);

			if (closeOnOutsideClick) {
				ui.sub('ui.windowresize', () => {
					const breakpoint = ui.lib.getBreakpoint();
					if (breakpoint === 'xs') {
						_this._removeMobileClass();
					}
				});
			}

			lightbox.open();
		},

		/**
		 * Removes mobile class of Glightbox library to enable close on outside click of modal in viewport XS.
		 *
		 * @private
		 */
		_removeMobileClass() {
			setTimeout(() => {
				$('body').removeClass('glightbox-mobile');
			}, 200);
		},

		/**
		 * Initializes a dialog whose content is an image
		 *
		 * @public
		 */
		showImageModal() {
			const onOpen = () => {
				this._addClassesOnOpen('modal--image');
			};
			const lightbox = this.initModal(this.options, onOpen);
			lightbox.open();
		},

		/**
		 * Add a class to glightbox body
		 *
		 * @param cssClass css class which is added to glightbox
		 * @private
		 */
		_addClassesOnOpen(cssClass) {
			document.getElementById('glightbox-body').classList.add(cssClass);
		},

		/**
		 * Fetches content of url to show this in Glightbox
		 *
		 * @param href url with content for Glightbox
		 * @private
		 */
		async _fetchUrl(href) {
			try {
				const response = await fetch(href);
				if (!response.ok) {
					throw new Error(`Modal: error while fetching ${href}`);
				}
				const text = await response.text();
				const parser = new DOMParser();
				const htmlDoc = parser.parseFromString(text, 'text/html');
				return htmlDoc.querySelectorAll('.main')[0].innerHTML;
			} catch (error) {
				console.error('Error fetching text:', error);
			}
		}
	});
}(ui.$));
