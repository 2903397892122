/* global ui */

(function ($) {
	$.widget('ipnp.article', {

		options: {
			id: null,
			articleid: '',
			cookiename: 'dhlah',
			// days cookie expiry
			cookieexpires: 365,
			// 2 days expiration for closed layers
			// 172800 seconds = 2 * 24 * 60 * 60
			hiddenexpire: 172800,
			trackingname: '',
			persist: false
		},

		_create() {
			this._initHideableArticle();
			this._removeCookie();
			this._showImageModal();
		},

		/**
		 * Creates the functionality to add a click event to remove cookies.
		 *
		 * @private
		 */
		_removeCookie() {
			this.element.find('a[href*="#optout-"]').on('click', (e) => {
				const href = $(e.target).attr('href');
				const cookieName = href.replace('#optout-', '');
				ui.lib.cookie.delete({
					name: cookieName
				});
			});
		},

		/**
		 * Initializes a hideable article and adds tracking events.
		 *
		 * @private
		 */
		_initHideableArticle() {
			const _this = this;
			const articleEl = this.element.find('div[data-role=hideable]');
			const firstArticle = articleEl[0];
			if (articleEl) {
				// Remove class as otherwise .checkbox is rendered too early (even if article should be hidden).
				articleEl.removeClass('invisible');
				// Don't hide article in edit-mode.
				if (ui.lib.isEditMode()) {
					return;
				}
				if (firstArticle !== undefined) {
					if (firstArticle.hasAttribute('data-tracking-name')) {
						this.options.trackingname = firstArticle.getAttribute('data-tracking-name');
					}
					if (firstArticle.hasAttribute('data-article-id')) {
						this.options.articleid = firstArticle.getAttribute('data-article-id');
						if (this.options.articleid === '') {
							this._processCookie(0);
						} else {
							ui.lib.cookie.getState({
								name: this.options.cookiename,
								state: this.options.articleid,
								callback(stateValue) {
									_this._processCookie(stateValue || 0);
								}
							});
						}
					} else {
						this._processCookie(0);
					}
				}
			}
		},

		/**
		 * Process the given cookie value.
		 *
		 * @param cookieValue The given cookie value.
		 * @private
		 */
		_processCookie(cookieValue) {
			const _this = this;
			const mustBeHidden = (Date.now() / 1000 - cookieValue) < this.options.hiddenexpire;
			ui.log(`${this.options.articleid} => ${cookieValue} -> ${mustBeHidden}`);
			if (mustBeHidden) {
				this.element.hide();
			} else {
				this.element.find('.btn').on('click', () => {
					_this._closeArticle(_this.element);
				});
				this.element.find('.btn').on('keyup', (e) => {
					const code = e.keyCode || e.which;
					if (code === 13) {
						_this._closeArticle();
					}
				});
				this.element.find('.checkbox').on('click', (e) => {
					if (e.target.nodeName === 'INPUT') {
						_this.options.persist = !_this.options.persist;
					}
				});
			}
		},

		/**
		 * Close the hideable article.
		 *
		 * @private
		 */
		_closeArticle() {
			this.element.hide();
			if (this.options.articleid) {
				if (this.options.persist) {
					ui.lib.cookie.setState({
						name: this.options.cookiename,
						state: this.options.articleid,
						value: Math.round(Date.now() / 1000),
						days: this.options.cookieexpires,
						stateexpires: this.options.hiddenexpire
					});
				}
			}
		},

		/**
		 * Show image in full size dimensions in modal.
		 *
		 * @private
		 */
		_showImageModal() {
			if (!ui.lib.isEditMode()) {
				this.element.find('.article__image--open-modal').on('click', (e) => {
					e.preventDefault();
					const modalOptions = {
						type: 'image',
						skin: 'dark',
						elements: [
							{
								href: e.currentTarget,
								type: 'image'
							}
						]
					};
					$.ipnp.Modal(modalOptions).showImageModal();
				});
			}
		}
	});
}(ui.$));
