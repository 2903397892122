/* global ui, YT, OneTrust,OnetrustActiveGroups */

(function ($) {
	$.widget('ipnp.video', {
		options: {
			consentCategory: 'YOU01',
			start: '0',
			allowFullscreen: '1'
		},

		_create() {
			const faqVideoList = document.querySelectorAll('.faq .video');
			ui.merge('ipnp.video', this, {
				data: () => {
					const faqVideosConfig = { videos: {} };
					for (const faqVideo of Object.values(faqVideoList)) {
						const { videoId } = faqVideo.dataset;
						const faqVideoConfig = ui.widgets['ipnp.video'].config[videoId];
						Object.assign(faqVideosConfig.videos, { [videoId]: faqVideoConfig });
					}
					return faqVideosConfig;
				}
			});

			if (this.element.find('.video__content').length > 0) {
				this.replaceYoutubeIframe();
			}
		},

		loadYtApi() {
			if (typeof YT !== 'object') {
				const tag = document.createElement('script');
				tag.src = 'https://www.youtube.com/iframe_api';
				const firstScriptTag = document.getElementsByTagName('script')[0];
				$(tag).insertAfter(firstScriptTag);
			}
		},

		loadYtVideo(_instance, videoId) {
			_instance.closest('.video--with-image').css('background', 'none');
			const iframe = _instance.find('.video__iframe')[0];

			const player = new YT.Player(iframe, {
				height: '100%',
				width: '100%',
				videoId,
				events: {
					onReady: function onReady(e) {
						e.target.playVideo();
						_instance.addClass('video--playing');
					}
				}
			});
		},

		replaceYoutubeIframe() {
			const _this = this;
			const consentNoteDiv = this.element.find('.video__consent');

			// check required cookie type
			let consentOk = true;
			// if OneTrust is not available -> not included -> author instance -> ignore consent

			// There exists a race condition between video.js and oneTrust (otSDKStub.js).
			// Sometimes the object "OnetrustActiveGroups" is available too late
			const oneTrustIntervall = window.setInterval(() => {
				if (_this.options.consentCategory && typeof (OnetrustActiveGroups) !== 'undefined') {
					window.clearInterval(oneTrustIntervall);

					if (this.options.consentCategory && typeof (OnetrustActiveGroups) !== 'undefined') {
						const categories = this.options.consentCategory.split(',');
						categories.forEach((category) => {
							if (OnetrustActiveGroups.indexOf(category.trim()) === -1) {
								consentOk = false;
							}
						});
					}

					if (consentOk) {
						// required cookies are active
						this.loadYtApi();

						consentNoteDiv.find('.video__consent-btn').hide();

						const $videoContent = this.element.find('.video__content');
						if (this.element.find('.video--with-image').length === 0) {
							const iframeSrc = `https://www.youtube.com/embed/${consentNoteDiv.prop('id')
							}?fs=${this.options.allowFullscreen
							}&amp;amp;rel=0&amp;amp;modestbranding=1&amp;amp;enablejsapi=1&amp;amp;start=${this.options.start}`;
							consentNoteDiv.remove();
							$videoContent.append(`<iframe src="${iframeSrc}" allow="autoplay" allowfullscreen="allowfullscreen" title="${
								consentNoteDiv.prop('id')}"></iframe>`);
						} else {
							$videoContent.on('click', () => {
								_this.loadYtVideo($videoContent, consentNoteDiv.prop('id'));
								consentNoteDiv.remove();
							});
						}
						$videoContent.removeClass('d-none');
					} else {
						// show button and text to accept required cookies
						consentNoteDiv.find('.btn').click({ component: this.element, conf: this.options }, (event) => {
							// accept required cookies
							const analytics = OneTrust.GetDomainData().Groups
								.filter((id) => id.OptanonGroupId === _this.options.consentCategory);
							let purpose;
							if (analytics && analytics.length > 0 && analytics[0]) {
								purpose = analytics[0].PurposeId;
							}
							OneTrust.setConsentProfile({ purposes: [{ Id: purpose, TransactionType: 'CONFIRMED' }] });
							OneTrust.Init();
							// load video
							_this.replaceYoutubeIframe(event.data.component);
						});
					}
				}
			}, 50);

			window.setTimeout(() => {
				window.clearInterval(oneTrustIntervall);
			}, 3000);
		}
	});
}(ui.$));
