/* global ui, OnetrustActiveGroups */

(function ($, ui) {
	$.widget('ipnp.application', {

		options: {
			filterRegex: /\[(.*?)]/g,
			applicationLoaded: false
		},

		_create() {
			let id;
			let integrationType;

			// Determine integration type according to markup in order to retrieve the correct ID.
			if (this.element.find('.application__iframe').length > 0) {
				id = this.element.find('.application__iframe')[0]?.id;
				integrationType = 'iframe';
			} else if (this.element.find('.ext-app-wrap .widget-placeholder').length > 0) {
				id = this.element.find('.ext-app-wrap .widget-placeholder')[0]?.id;
				integrationType = 'ajax';
			} else if (this.element.find('.ext-app-wrap div div[react]').length > 0) {
				id = this.element.find('.ext-app-wrap div div[react]')[0]?.getAttribute('name');
				integrationType = 'react';
			}

			if (integrationType === 'iframe') {
				this.initIframe();
			} else {
				ui.merge('ipnp.application', this);
				// Extend the options of the "Application"-component because the ID attribute cannot be used at the location of the widgets DOM.
				$.extend(this.options, ui.widgets['ipnp.application'].config[id]);
				this.initTransclusion();
			}
		},

		/**
		 * Initializes the Iframe embed.
		 */
		initIframe() {
			const $iframe = this.element.find('.application__iframe');

			if ($iframe) {
				// set iframe src from data-src attribute + append dynamic application params
				const iframeSrcArr = [];
				iframeSrcArr.push($iframe.attr('src'));
				const dynamicAppParams = this._getRequestParams($iframe.data('app-params'));

				if (dynamicAppParams) {
					if ($iframe.attr('src').indexOf('?') > 0) {
						iframeSrcArr.push('&');
						iframeSrcArr.push(dynamicAppParams);
					} else {
						iframeSrcArr.push('?');
						iframeSrcArr.push(dynamicAppParams);
					}
				}

				$iframe.attr('src', iframeSrcArr.join(''));
			}
		},

		/**
		 * Initializes Ajax transclusion & react app integration.
		 */
		initTransclusion() {
			const _this = this;
			if (_this.options.id) {
				const consentOk = this._checkConsent();

				if (consentOk && _this.options.integrationType === 'react' && _this.options.initReact) {
					// Execute the configured initialization script for the React application.
					// integrated app has to call ui.pub('application.loaded', {id: 'psf-app'});
					// id corresponds to the "element name" in the application-component
					const decodedReactInitScript = this._decodeHTML(_this.options.initReact);
					ui.sub('application.loaded', (e, data) => {
						if (!_this.options.applicationLoaded
							&& ((data.id && data.id === _this.options.id)
								|| (data['id-pref'] && _this.options.id.indexOf(data['id-pref']) !== -1))) {
							ui.log(`Starting React app with ID [${_this.options.id}].`);
							_this.options.applicationLoaded = true;
							eval(decodedReactInitScript);
						}
					});
				}

				this.replaceContent(consentOk);
			}
		},

		/**
		 * Loads referenced target application and adds its content to the current 'Application' component.
		 * Implements specific error handling methods/cases.
		 *
		 * @param {Boolean} consentOk - Is the necessary consent category met.
		 */
		replaceContent(consentOk) {
			const _this = this;
			let replaceableDiv;
			let urlParams;
			if (_this.options.integrationType === 'ajax') {
				replaceableDiv = $(`.widget-placeholder#${_this.options.id}`);
				// Retrieve URL parameters.
				urlParams = this._appendApplicationParameter();
			} else if (_this.options.integrationType === 'react') {
				replaceableDiv = $(`[name="${_this.options.id}"]`);
				urlParams = '';
			}
			if (consentOk) {
				const ajaxOptions = {};
				ajaxOptions.cache = true;
				// Set a global(!) timeout if configured.
				if (_this.options.timeout) {
					ajaxOptions.timeout = Number(_this.options.timeout);
				}
				$.ajaxSetup(ajaxOptions);

				const content = $('<div></div>').load(_this.options.href + urlParams, (response, status) => {
					if (status === 'error' || status === 'timeout') {
						// Error handling according to the configuration.
						_this.errorHandling(_this.options.errorHandling, replaceableDiv);
					} else {
						_this.integrateStyling(content);
						_this.replaceHTML(content);

						if (_this.options.htmlSelector) {
							replaceableDiv.replaceWith($(content).find(_this.options.htmlSelector));
						} else {
							replaceableDiv.replaceWith($(content));
						}

						_this.integrateScripts(content);
					}
				});
			} else if (_this.options.consentFallbackText) {
				const fallbackText = $(`<div class="application__error">
											<div class="application__headline">${_this.options.i18n.hintTitle}</div>
											${_this.options.consentFallbackText}
										</div>`);
				replaceableDiv.replaceWith($(fallbackText));
			}
		},

		/**
		 * Handles the selected error handling option.
		 *
		 * @param {String} errorHandlingOpt - selected error handling option.
		 * @param {Object} replaceableDiv - DIV-element which should be replaced according to the error handling option.
		 */
		errorHandling(errorHandlingOpt, replaceableDiv) {
			const _this = this;
			switch (errorHandlingOpt) {
				case 'errorFadeOut':
					if (!ui.lib.isEditMode()) {
						replaceableDiv.parents('.application').hide();
					}
					break;
				case 'errorText':
					if (_this.options.errorText) {
						const errorText = $(`<div class="application__error">
												<div class="application__headline">${_this.options.i18n.errorTitle}</div>
												<p>${_this.options.errorText}</p>
											</div>`);
						replaceableDiv.replaceWith($(errorText));
					}
					break;
				default:
					ui.log('Error while retrieving error handling option.');
			}
		},

		/**
		 * Integrates all CSS references of the referenced target application and
		 * adds additional, configured CSS.
		 *
		 * @param {Object} content - content of the referenced target application.
		 */
		integrateStyling(content) {
			const _this = this;

			// Append CSS to the <head>.
			if (_this.options.includeStyling === 'true') {
				const replacedContent = content.find('link[rel~=stylesheet]').each(function () {
					const linkItem = $(this);
					const linkItemHref = linkItem.attr('href');
					if (linkItemHref && linkItemHref.length > 3) {
						// don't prepend prefix for fully-qualified URLs
						if (linkItemHref.indexOf('http') === 0) {
							linkItem.attr('href', linkItemHref);
						} else {
							linkItem.attr('href', _this.options.stylingPrefix + linkItemHref);
						}
						linkItem.addClass('application__styles');
					}
				});

				$('head').append(replacedContent);
			}

			const styleURLs = _this._decodeHTML(_this.options.styling).split(',');
			$.each(styleURLs, (index, styleURL) => {
				if (styleURL.length > 3) {
					$('<link />', {
						class: 'application__styles',
						rel: 'stylesheet',
						href: styleURL
					}).appendTo('head');
				}
			});
		},

		/**
		 * Integrates all JS references of the referenced target application and
		 * adds additional, configured JS.
		 *
		 * @param {Object} content - content of the referenced target application.
		 */
		integrateScripts(content) {
			const _this = this;

			const scriptDivId = `application__scripts_${_this.options.id}`;
			const $body = $('body');
			let scriptDiv = $body.find(`div#${scriptDivId}`);
			if (scriptDiv.length === 0) {
				$body.append(`<div id="${scriptDivId}"/>`);
				scriptDiv = $body.find(`div#${scriptDivId}`);
			}
			// Append JS to the <body>.
			if (_this.options.includeScripts === 'true') {
				const replacedContent = content.find('script').each(function () {
					const scriptItem = $(this);
					const scriptItemSrc = scriptItem.attr('src');
					if (scriptItemSrc && scriptItemSrc.length > 3) {
						// don't prepend prefix for fully-qualified URLs
						if (scriptItemSrc.indexOf('http') === 0) {
							scriptItem.attr('src', scriptItemSrc);
						} else {
							scriptItem.attr('src', _this.options.scriptsPrefix + scriptItemSrc);
						}
					}
				});

				$(scriptDiv).append(replacedContent);
			}

			const scriptURLs = _this._decodeHTML(_this.options.scripts).split(',');
			$.each(scriptURLs, (_index, scriptURL) => {
				if ($(`script[src="${scriptURL}"]`).length < 1 && scriptURL.length > 3) {
					$(scriptDiv).append(`<script src="${scriptURL}"></script>`);
				}
			});
		},

		/**
		 * Replaces HTML content based on a configured filter.
		 *
		 * @param {Object} content - content of the referenced target application.
		 */
		replaceHTML(content) {
			const _this = this;
			// Retrieve the formatted filter String and get all configured filters.
			const filterString = _this.options.htmlFilter;
			const { filterRegex } = _this.options;
			let match = filterRegex.exec(filterString);
			while (match != null) {
				const filter = match[1].split(',');
				const filterSelector = _this._decodeHTML(decodeURIComponent(filter[0]));
				const filterAttribute = _this._decodeHTML(decodeURIComponent(filter[1]));
				const filterValue = _this._decodeHTML(decodeURIComponent(filter[2]));
				// Find and replace HTML content.
				content.find(filterSelector).each(function () {
					$(this).attr(filterAttribute, eval(filterValue));
				});
				match = filterRegex.exec(filterString);
			}
		},

		/**
		 * Create a URL parameter list with language, static and application specific parameters if set.
		 *
		 * @returns {String} A string containing a URL parameter list.
		 */
		_appendApplicationParameter() {
			const _this = this;
			let urlParams = '';
			if (_this.options.urlParamsAjax) {
				// Add language and static parameter(s) if set.
				urlParams += this._decodeHTML(_this.options.urlParamsAjax);
			}
			if (_this.options.appParams) {
				// Add application specific parameters if set.
				urlParams += _this.options.urlParamsAjax ? '&' : '?';
				const appParams = _this.options.appParams.split(',');
				let paramCount = 0;
				$.each(appParams, (_index, appParam) => {
					const paramValue = ui.lib.getParamValue(appParam);
					if (paramValue) {
						if (paramCount > 0) {
							urlParams += '&';
						}
						urlParams += `${appParam}=${paramValue}`;
						paramCount += 1;
					}
				});
			}
			return urlParams;
		},

		/**
		 * Returns the filtered list of parameters from current Request URL machting a given list of parameter names.
		 *
		 * @param params
		 * @returns {string} the request parameters as string
		 * @private
		 */
		_getRequestParams(params) {
			const appParams = new URLSearchParams();
			const queryParams = new URLSearchParams(window.location.search);

			if (params) {
				params.split(',').forEach((param) => {
					if (queryParams.has(param)) {
						appParams.append(param, queryParams.get(param));
					}
				});
			}

			return appParams.toString();
		},

		/**
		 * Checks the given consent of current request by comparing consent categories of configuration.
		 *
		 * @returns {boolean} true if consent matches categories from configuration or if no consent categories defined
		 * @private
		 */
		_checkConsent() {
			let consentOk = true;
			if (this.options.consentCategories && typeof (OnetrustActiveGroups) !== 'undefined') {
				this.options.consentCategories.split(',').forEach((category) => {
					if (OnetrustActiveGroups && OnetrustActiveGroups.indexOf(category.trim()) === -1) {
						consentOk = false;
					}
				});
			}

			return consentOk;
		},

		/**
		 * Decodes an HTML encoded string.
		 *
		 * @param {String} encodedHTML - an HTML encoded string.
		 *
		 * @returns {String} A HTML decoded string.
		 */
		_decodeHTML(encodedHTML) {
			return $('<div/>').html(encodedHTML).text();
		}
	});
}(ui.$, ui));
