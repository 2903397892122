/* global ui */

(function ($, ui) {
	const uicookie = {
		cookie: {

			/**
			 * Sets a cookie. Data must contain: name and value; Data can contain: days, domain, path, sameSite and secure (boolean)
			 *
			 * @param data Data object containing: name, value, days, domain, path, sameSite and secure (boolean)
			 * @private
			 */
			set(data) {
				let expires;
				let domain;
				let path = '; path=';
				let sameSite = '; SameSite=';
				const secure = data.secure ? '; Secure' : '';
				if (data.days) {
					const date = new Date();
					// 'setTime' needs milliseconds as parameter, therefore the number of days will be converted into ms (1 day =
					// 86400000 ms = 24 * 60 * 60 * 1000).
					date.setTime(date.getTime() + (data.days * 86400000));
					expires = `; expires=${date.toGMTString()}`;
				} else {
					expires = '';
				}
				if (data.domain) {
					const url = new URL(window.location);
					const split = url.host.split('.');
					if (split.length >= 2) {
						domain = `; domain=${split[split.length - 2]}.${split[split.length - 1]}`;
					}
				} else {
					domain = '';
				}
				if (data.path) {
					path += data.path;
				} else {
					path += '/';
				}
				if (data.sameSite) {
					sameSite += data.sameSite;
				} else {
					sameSite += 'Lax';
				}
				document.cookie =
					`${encodeURIComponent(data.name)}=${encodeURIComponent(data.value)}${expires}${domain}${path}${sameSite}${secure}`;
			},

			/**
			 * Get a cookie by name. Additionally, a callback will be called utilizing the cookie value.
			 *
			 * @param data Data object containing: name and callback function
			 * @returns {*} Applies the callback if given.
			 * @private
			 */
			get(data) {
				const nameEQ = `${encodeURIComponent(data.name)}=`;
				const cookieArray = document.cookie.split(';');
				for (let index = 0; index < cookieArray.length; index++) {
					let cookie = cookieArray[index];
					while (cookie.charAt(0) === ' ') {
						cookie = cookie.substring(1, cookie.length);
					}
					if (cookie.indexOf(nameEQ) === 0) {
						if (data.callback) {
							return data.callback(decodeURIComponent(cookie.substring(nameEQ.length, cookie.length)));
						}
					}
				}
				return data.callback(null);
			},

			/**
			 * Delete a cookie by name.
			 *
			 * @param data Data object containing: name.
			 * @private
			 */
			delete(data) {
				this.set({
					name: data.name,
					value: '',
					days: -1
				});
			},

			/**
			 * Set the state of a cookie.
			 *
			 * @param data Data object containing: name, value, state, state expiration, days
			 * @returns {boolean}
			 * @private
			 */
			setState(data) {
				const _this = this;
				this.get({
					name: data.name,
					callback(cookieValue) {
						const states = JSON.parse(cookieValue) || {};
						_this.expireStates(states, data.stateexpires);
						states[data.state] = data.value;
						_this.set({
							name: data.name,
							value: JSON.stringify(states),
							days: data.days
						});
					}
				});
				return true;
			},

			/**
			 * Get the state of a cookie by name.
			 *
			 * @param data Data object containing: name, state
			 * @private
			 */
			getState(data) {
				this.get({
					name: data.name,
					callback(cookieValue) {
						const states = JSON.parse(cookieValue) || {};
						return data.callback(states[data.state] || null);
					}
				});
			},

			/**
			 * State expiration.
			 *
			 * @param states States which should expire.
			 * @param expirySeconds Expiration of states.
			 * @private
			 */
			expireStates(states, expirySeconds) {
				let age = 0;
				for (const state in states) {
					if (states.hasOwnProperty(state)) {
						age = (Date.now() / 1000) - states[state];
						if (age > expirySeconds) {
							ui.log(`expiring  state: ${state}, with age: ${age}`);
							delete states[state];
						}
					}
				}
			}
		}
	};

	$.extend(ui.lib, uicookie);
}($, ui));
