/* global ui */

(function ($) {
	$.widget('ipnp.progress-bar', {

		_create() {
			const _this = this;
			if (this.element.find('.progress-bar__step').length > 0) {
				const nextButton = this.element.find('.btn--next');
				const prevButton = this.element.find('.btn--prev');
				const jumpButton = this.element.find('.jump-to__link');
				if (jumpButton.length > 0) {
					jumpButton.each(function () {
						$(this).click(function (e) {
							e.preventDefault();
							const that = $(this);
							_this._setStep('jump', that);
						});
					});
				}

				if (nextButton.length > 0) {
					nextButton.each(function () {
						$(this).click(function () {
							_this._handleNextButtonClick($(this));
						});
					});
				}

				if (prevButton.length > 0) {
					prevButton.each(function () {
						$(this).click(function () {
							const that = $(this);
							_this._setStep('prev', that);
						});
					});
				}
			}
		},

		/**
		 * Set a step - different handling for "back" and "next".
		 *
		 * @param step The step selection (can be: 'next', 'prev', 'jump' or 'goLast').
		 * @param btn The clicked button.
		 * @private
		 */
		_setStep(step, btn) {
			const currentActive = this.element.find('.progress-bar__step.progress-bar__step--current');
			const currentContent = currentActive.data('content');
			let setActive;
			if (step === 'prev') {
				setActive = currentActive.prev().prev();
			} else if (step === 'next') {
				setActive = currentActive.next().next();
			} else if (step === 'jump') {
				setActive = $(`#${$(btn).data('target')}`);
			}
			const setContent = setActive.data('content');

			currentActive.removeClass('progress-bar__step--current');
			currentActive.find('span[aria-selected]').attr('aria-selected', 'false');
			if (step === 'jump') {
				if (currentActive.closest('form').length > 0 && currentActive.closest('form').valid()) {
					currentActive.addClass('progress-bar__step--step-done');
				} else {
					currentActive.removeClass('progress-bar__step--step-done');
				}
			} else if (step === 'next') {
				currentActive.addClass('progress-bar__step--step-done');
			} else if (step === 'prev') {
				currentActive.removeClass('progress-bar__step--step-done');
			}
			setActive.addClass('progress-bar__step--current');
			setActive.find('span[aria-selected]').attr('aria-selected', 'true');

			$(`#${currentContent}`).addClass('progress-bar__content--inactive');
			$(`#${setContent}`).removeClass('progress-bar__content--inactive');
			if (btn) {
				window.scrollTo(0, btn.closest('.progress-bar').offset().top);
			}
		},

		/**
		 * Handle the click on a "Next" button (mostly for validation of the current step).
		 *
		 * @param nextButton The clicked "Next" button.
		 * @private
		 */
		_handleNextButtonClick(nextButton) {
			const _this = this;
			const form = nextButton.closest('form');
			if (form.length > 0) {
				// validate only visible error fields, error fields in hidden conditional containers are not considered
				const isNotValid = !form.valid() && nextButton.closest('.progress-bar__content')
					.find('.form-field--has-error:visible').length > 0;
				const isValid = !isNotValid;
				if (!ui.lib.isEditMode()) {
					if (isValid) {
						form.validate().resetForm();
					} else {
						return;
					}
				}

				if (isValid) {
					_this._handleValidNextStep(form);
				}
			}
			_this._setStep('next', nextButton);
		},

		/**
		 * Handle click on "Next" button if form data is valid.
		 *
		 * @param form The current form element.
		 * @private
		 */
		_handleValidNextStep(form) {
			const pTags = form.find('p[class=with-placeholder]');
			let text = '';
			pTags.children().each(function () {
				text += $(this).text();
				if ($(this).is('br')) {
					if (text.trim() === '') {
						$(this).addClass('hidden');
					} else {
						$(this).removeClass('hidden');
					}
					text = '';
				}
			});
		}
	});
}(ui.$, window));
