/* global ui */
(function ($) {
	$.widget('ipnp.Dropdown', {
		_create() {
			if (this.element.is(':visible')) {
				this.calcHeight(this.element);
			}
		},

		/**
		 * Calculates the height if the label has multiple lines
		 * @private
		 */
		calcHeight(dropdown) {
			const labelHeight = dropdown.find('.dropdown__label').height();
			// checks if label has multiple lines
			if (labelHeight > 17) {
				const $select = dropdown.find('select');
				const selectHeight = $select.outerHeight(true);
				$select.css('height', selectHeight + labelHeight - 17);
				$select.css('paddingTop', labelHeight);
			}
		}
	});
}(ui.$));
