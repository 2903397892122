/* global ui */

(function ($) {
	$.widget('ipnp.conditional-container', {
		options: {
			operator: '',
			value: ''
		},

		_create() {
			ui.merge('ipnp.conditional-container', this);
			const _this = this;

			// wait until placeholder replacement in the conditional container form is ready
			ui.sub('ui.form.ready', (e, data) => {
				// initialize conditional container only once
				if (!data.form.hasClass('conditional-container--initialized') && data.form.find('.conditional-container').length > 0) {
					data.form.addClass('conditional-container--initialized');

					data.form.find('.conditional-container').each((_index, element) => {
						const $conditionalContainer = $(element);

						// Show or hide condition container.
						_this._toggleConditions($conditionalContainer, data.form, true);

						// Initial toggle default condition.
						_this._toggleDefault($conditionalContainer);

						// Set and remove ignore class to bypass validator.
						_this._toggleValidator($conditionalContainer);
					});
				}
			});
		},

		_toggleConditions($conditionalContainer, $form, init) {
			const _this = this;
			const ccId = $conditionalContainer.attr('id');

			$conditionalContainer.find('> .cc__condition').not('.cc__condition--default').each((_index, element) => {
				const conditionId = $(element).attr('id');
				const conditionConf = ui.get('ipnp.conditional-container')[conditionId];
				const $element = $form.find(`[name="${conditionConf.element}"]`);

				const matchingCondition = _this._toggleCondition($element, conditionConf, $(element));
				_this._toggleDefault($conditionalContainer);

				if (init === true) {
					let simulationEvent = '';
					if (ui.lib.isEditMode()) {
						simulationEvent = ` cc__condition_${ccId}`;
					}

					$element.on(`change${simulationEvent}`, () => {
						_this._toggleConditions($conditionalContainer, $form, false);
						_this._toggleDefault($conditionalContainer);
					});
				}

				/* Unify client validation with backend validation by aborting early
				 * disabled because this will break existing forms (IPNP-3463)
				 *
				 * if (matchingCondition) {
				 * 	return false;
				 * }
				 * return true;
				 */
			});
		},

		/**
		 * toggle the visibility of conditions
		 * 1. condition is evaluated to be true
		 * 2. no other higher prioritised condition is active instead of the default one
		 */
		_toggleCondition($element, config, container) {
			if (this._validateCondition(this._getValue($element), config.operator, config.value)) {
				container.addClass('cc__condition--active');
				container.prevAll('.cc__condition.cc__condition--active').removeClass('cc__condition--active');
				container.nextAll('.cc__condition.cc__condition--active').removeClass('cc__condition--active');
				this._resizeDropdown(container);

				return true;
			}
			container.removeClass('cc__condition--active');
			return false;
		},

		_toggleDefault($conditionalContainer) {
			const defContainer = $conditionalContainer.find(' > .cc__condition--default');
			if ($conditionalContainer.find(' > .cc__condition.cc__condition--active').not('.cc__condition--default').length
				> 0) {
				defContainer.removeClass('cc__condition--active');
			} else {
				defContainer.addClass('cc__condition--active');
				this._resizeDropdown(defContainer);
			}
			this._toggleValidator($conditionalContainer);
		},

		/**
		 * toggle elements for validation
		 */
		_toggleValidator($conditionalContainer) {
			$conditionalContainer.find('select, input, textarea').addClass('form-field--ignore');
			$conditionalContainer.find('.cc__condition--active').find('select, input:not(.datepicker), textarea')
				.removeClass('form-field--ignore')
				// Filter datepicker and toggle validator ignore for :hidden transferfield.
				.filter(function () {
					const $cur = $(this);
					if ($cur.hasClass('datepicker')) {
						$cur.next('[type="hidden"]').removeClass('form-field--ignore');
					}
					if ($cur.parents('.cc__condition').length !== $cur.parents('.cc__condition.cc__condition--active').length) {
						$cur.addClass('form-field--ignore');
					}
					return true;
				});
		},

		/**
		 * validate a condition - test a value
		 */
		_validateCondition(value, operator, test) {
			switch ($('<div/>').html(operator).text()) {
				case '<':
					return (parseFloat($.ipnp.form().makeParseableValue(value)) < parseFloat($.ipnp.form().makeParseableValue(test)));
				case '>':
					return (parseFloat($.ipnp.form().makeParseableValue(value)) > parseFloat($.ipnp.form().makeParseableValue(test)));
				case '<=':
					return (parseFloat($.ipnp.form().makeParseableValue(value)) <= parseFloat($.ipnp.form().makeParseableValue(test)));
				case '>=':
					return (parseFloat($.ipnp.form().makeParseableValue(value)) >= parseFloat($.ipnp.form().makeParseableValue(test)));
				case '=':
					return (value === test);
				case '!=':
					return (value !== test);
				default:
					// Should never be reached! (Added 'default' case to fix SonarQube report.)
					return true;
			}
		},

		_getValue($el) {
			let value = $el.val();
			if ($el.is(':radio')) {
				value = $el.is(':checked') ? $el.filter(':checked').val() : '';
			}
			if ($el.is(':checkbox')) {
				value = $el.is(':checked') ? value : '';
			}
			return value;
		},

		/**
		 * Triggers function to update height of dropdowns in active conditional container
		 */
		_resizeDropdown($container) {
			const dropdowns = $container.find('.dropdown:visible');
			dropdowns.each((index, dropdown) => {
				$.ipnp.Dropdown().calcHeight($(dropdown));
			});
		}
	});
}(ui.$));
