/* global ui */

(function ($) {
	$.widget('ipnp.form-routing', {

		options: {
			conditions: [],
			fallbackRedirectPath: ''
		},

		_create() {
			ui.merge('ipnp.form-routing', this);
		},

		modifyRoutingForm(form) {
			// get the matching condition to the given form data
			const matchingCondition = this.options.conditions.find((condition) => {
				// get element field to compare the value
				const elementToCompare = document.getElementById(condition.elementId);

				if (elementToCompare) {
					switch (condition.operator) {
						case '=':
							return elementToCompare.value === condition.value;
						case '!=':
							return elementToCompare.value !== condition.value;
						case '<=':
							return parseFloat($.ipnp.form().makeParseableValue(elementToCompare.value)) <= parseFloat(
								$.ipnp.form().makeParseableValue(condition.value)
							);
						case '>=':
							return parseFloat($.ipnp.form().makeParseableValue(elementToCompare.value)) >= parseFloat(
								$.ipnp.form().makeParseableValue(condition.value)
							);
						case '<':
							return parseFloat($.ipnp.form().makeParseableValue(elementToCompare.value)) < parseFloat(
								$.ipnp.form().makeParseableValue(condition.value)
							);
						case '>':
							return parseFloat($.ipnp.form().makeParseableValue(elementToCompare.value)) > parseFloat(
								$.ipnp.form().makeParseableValue(condition.value)
							);
						default:
							ui.log(`Operator ${condition.operator} not allowed.`);
					}
				}

				return false;
			});
			// set the form action to the routing path and override method POST->GET
			if (matchingCondition) {
				$(form).attr('action', matchingCondition.redirectPath);
				$(form).attr('method', 'GET');
			} else {
				$(form).attr('action', this.options.fallbackRedirectPath);
				$(form).attr('method', 'GET');
			}
		}
	});
}(ui.$));
