/* global ui */

(function ($) {
	$.widget('ipnp.faq-item', {

		options: {},

		_create() {
			const _this = this;
			this.element.find('.faq-feedback__buttons .btn').on('click', (e) => {
				ui.stop(e);
				_this._handleFeedbackButton($(e.currentTarget));
			});
		},

		_handleFeedbackButton($clickedBtn) {
			const faqItem = $clickedBtn.closest('.faq .accordion-item');

			// hide feedback panel
			$clickedBtn.closest('.faq-feedback').hide();

			// show confirm message
			faqItem.find('.faq__confirm').show();

			// track feedback
			ui.pub('tracking.trackGenericEvent', {
				'obj': {
					'detail': {
						'ipnp': {
							'events': ['faqevaluation']
						},
						'evaluation': {
							'originpagename': document.location.protocol + '//' + document.location.host + document.location.pathname,
							'faqtitle': faqItem.find('.accordion-item__header').text().trim(),
							'faqId': faqItem.find('a').attr('id'),
							'result': $clickedBtn.text()
						}
					}
				}
			});
		}
	});
}(ui.$));
