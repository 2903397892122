/* global ui */

(function ($) {
	$.widget('ipnp.upload', {
		options: {
			// 10MB fallback
			maxAbsoluteUploadSize: 10485760,
			maxFileUploadSize: 0,
			allowedExtensions: []
		},

		_create() {
			ui.merge('ipnp.upload', this);
			const _this = this;
			const input = this.element.find('.upload__file');
			// max addable fields
			const max = input.data('uploads');
			const btn = this.element.find('.btn');
			// counter for generated fields
			let generated = 0;
			const rootId = input.attr('id');

			// open upload dialog on enter
			btn.on('keyup', (e) => {
				const code = e.keyCode || e.which;
				if (code === 13) {
					_this.element.find('.upload__file').click();
				}
			});

			// Check if file size can be checked with current browser.
			let fileCheckPossible;
			if (window.FileReader && window.File && window.FileList && window.Blob) {
				fileCheckPossible = true;
				ui.log('Checking for file sizes is supported!');
			} else {
				fileCheckPossible = false;
				ui.log('Checking for file sizes is not supported!');
			}

			// Click remove trigger.
			this._on({
				'click .upload__remove': function (e) {
					ui.stop(e);
					const file = $(e.currentTarget);
					file.closest('.upload__list').remove();
					// count gen fields
					generated -= 1;
					// update ids
					_this._checkFileIds(rootId, generated, max, btn);
				}
			});

			// Add or reset a file.
			this._on({
				'change .upload__file': function (e) {
					const file = $(e.currentTarget);
					let txt = file.val();
					// check if user reset file
					const reset = (txt === '');
					if (!reset) {
						if (generated <= max) {
							const name = txt.split('\\');
							// read filename
							txt = name[name.length - 1];
							// Add file size in parenthesis.
							if (fileCheckPossible) {
								let fileSize = file[0].files[0].size;
								fileSize = Number(fileSize / (1024 * 1024)).toFixed(2);
								txt += ` (~${Number(fileSize).toLocaleString('de')} MB)`;
							}
							// add new field
							file.after('<input type="file" class="upload__file" tabindex="-1" />');
							// add remove trigger
							file.wrap('<span class="upload__list"/>')
								.after(`<a href="#" class="upload__remove"></a><span class="upload__filename">${txt}</span>`)
								.closest('.upload__list').insertAfter(_this.element.find('label'));
							// count gen fields
							generated += 1;
						}
					}
					// update ids
					this._checkFileIds(rootId, generated, max, btn);
				}
			});

			this._addValidators(rootId, fileCheckPossible);

			// Does prevent the opening of the file upload if the maximum number of uploads is reached.
			this._on({
				'click .upload__file': function (e) {
					if (generated === max) {
						e.preventDefault();
					}
				}
			});

			const form = this.element.closest('form');
			form.prop('enctype', 'multipart/form-data');
		},

		_addValidators(rootId, fileCheckPossible) {
			// Don't validate anything if checking for file sizes isn't possible.
			if (fileCheckPossible) {
				this._addFileSizeValidator(rootId);
				this._addUploadSizeValidator(rootId);
				this._addUploadExtensionValidator(rootId);
			}
		},

		// Validator for the individual file size.
		_addFileSizeValidator(rootId) {
			const _this = this;
			$.validator.addMethod(`file_size_${rootId.replace(/-/g, '_')}`, () => {
				// Delete previous help-blocks (because updating files changes logic in DOM).
				const upload = _this.element;
				const helpBlockId = `#${rootId}-error`;
				const helpBlock = upload.find(helpBlockId);
				if (helpBlock.length > 0) {
					helpBlock.remove();
				}
				if (_this.options.maxFileUploadSize === 0) {
					return true;
				}
				let passed = true;
				upload.each(function () {
					const uploadInput = $(this).find('input[type=file]');
					uploadInput.each(function () {
						if ((this.files[0]) && (this.files[0].size > _this.options.maxFileUploadSize)) {
							passed = false;
							return false;
						}
						return true;
					});
				});
				return passed;
			});
		},

		// Validator for file sizes accumulated for the entire form.
		_addUploadSizeValidator(rootId) {
			const _this = this;
			$.validator.addMethod(`upload_size_${rootId.replace(/-/g, '_')}`, () => {
				// Delete previous help-blocks (because updating files changes logic in DOM).
				const upload = _this.element;
				const helpBlockId = `#${rootId}-error`;
				const helpBlock = upload.find(helpBlockId);
				if (helpBlock.length > 0) {
					helpBlock.remove();
				}
				let passed = true;
				const form = $(upload).closest('form');
				let cumulatedUploadSize = 0;
				form.each(function () {
					const uploadInput = $(this).find('input[type=file]');
					uploadInput.each(function () {
						if (this.files[0]) {
							const fileSize = this.files[0].size;
							cumulatedUploadSize += fileSize;
							if (cumulatedUploadSize > _this.options.maxAbsoluteUploadSize) {
								passed = false;
							}
						}
					});
				});
				return passed;
			});
		},

		// Validator for file extensions.
		_addUploadExtensionValidator(rootId) {
			const _this = this;
			$.validator.addMethod(`upload_extension_${rootId.replace(/-/g, '_')}`, () => {
				const upload = _this.element;
				let result = true;
				if (_this.options.allowedExtensions.length > 0) {
					upload.each(function () {
						const uploadInput = $(this).find('input[type=file]');
						uploadInput.each(function () {
							if (this.files[0] && this.files[0].name !== undefined) {
								result = false;
								const filename = this.files[0].name;
								let extension = filename.split('.').pop();
								extension = extension.toLowerCase();
								_this.options.allowedExtensions.forEach((allowed) => {
									if (allowed === extension) {
										result = true;
									}
								});
							}
						});
					});
				}
				return result;
			});
		},

		// Update file ids after create or remove.
		_checkFileIds(rootId, generated, max, btn) {
			this.element.find('.upload__file').each(function (k) {
				// k === 0 equals new file upload field
				if (k === 0) {
					// create id
					let id = `${rootId}_${generated}`;
					if (generated === 0) {
						id = rootId;
					}
					// set id
					$(this).attr('id', id).attr('name', id);
				} else {
					// renumerate as elements could have been removed and readded
					// this makes sure that we don't get any duplicates
					let id = generated - k;
					if (id === 0) {
						id = rootId;
					} else {
						id = `${rootId}_${id}`;
					}
					// set id
					$(this).attr('id', id).attr('name', id);
				}
			});

			// hide add trigger if max reached
			if (generated === max) {
				btn.hide();
			} else {
				btn.show();
			}
		}
	});
}(ui.$));
