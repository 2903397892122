/* global ui */
(function ($) {
	/**
	 * Widget Constructor
	 *
	 * @constructor
	 */
	$.widget('ipnp.search-autocomplete', {
		options: {
			host: '',
			AJAX_URL: '/search/magnolia-collection/suggest?q='
		},

		_create() {
			ui.merge('ipnp.search-autocomplete', this);

			const _this = this;

			// implementation of autocompletion
			this.element.on('keyup', () => {
				// initialize the autocomplete
				this._init();

				// execute only if the search termin contains more than two letters
				if (this.element.val() !== undefined && this.element.val().length > 2) {
					const query = this.element.val().toLowerCase();

					try {
						$.ajax({
							url: `${_this.options.host + _this.options.AJAX_URL + encodeURIComponent(query)}`,
							dataType: 'json',
							type: 'GET',
							contentType: 'application/json; charset=utf-8',
							async: true,
							success(data) {
								const sorted = _this._sortSuggestions(data?.suggest?.default[query]?.suggestions);
								_this.element.autocomplete('option', 'source', sorted);
								// Sources are set, so enable the widget
								_this.element.autocomplete('enable');
							},
							error(jqXHR, textStatus) {
								if (_this.element.data('ui-autocomplete') !== undefined) {
									_this.element.autocomplete('close');
								}
								ui.log(`${this.widgetName} - Suggestion xhr error ${textStatus}`);
							}
						});
					} catch (ex) {
						ui.log(`ERROR ${this.widgetName}`);
						ui.log(ex.stack);
					}
				}
			});

			this._handleResize();
		},

		/**
		 * Initialize the autocomplete widget (but did not enable it yet, because sources are missing)
		 */
		_init() {
			const $inputField = this.element;
			const $inputFieldParent = this.element.parent();

			this.element.autocomplete({
				select(event, ui) {
					$inputField.val(ui.item.label);
					$inputField.closest('.input-group').removeClass('input-group--autocomplete');
					$inputField.closest('form').submit();
				},
				open() {
					// add border
					$inputField.closest('.input-group').addClass('input-group--autocomplete');

					// set autocomplete width
					let autoCompleteWidth = $inputField.outerWidth();
					if ($inputField.next('.input-group__clear').length > 0) {
						autoCompleteWidth += $inputField.prev('.input-group__prepend').outerWidth();
					}
					$inputFieldParent.find('.ui-autocomplete').css('width', autoCompleteWidth);
					$inputFieldParent.find('.ui-autocomplete').css('left', 0);
				},
				appendTo: $inputFieldParent,
				minLength: 3,
				disabled: true
			});
		},

		/**
		 * Sort the suggestions by weight
		 *
		 * @param suggestions the suggestions array
		 */
		_sortSuggestions(suggestions) {
			const values = [];
			// push all suggestion objects to an array
			$.map(
				suggestions,
				(item) => {
					values.push([item.term, item.weight]);
				}
			);
			// sort this array by its weight (higher weight > lower weight)
			values.sort((a, b) => b[1] - a[1]);
			// only return sorted suggestion (without weight)
			return $.map(
				values,
				(item) => item[0]
			);
		},

		/**
		 * Close autocomplete after resize
		 */
		_handleResize() {
			ui.sub('ui.windowresize', () => {
				if (this.element.data('ui-autocomplete') !== undefined) {
					this.element.autocomplete('close');
				}
			});
		}
	});
}(ui.$));
