(function (ui) {
	/* global ui */

	/**
	 * register widgets
	 */
	ui.register({
		widget: 'window'
	});

	ui.register({
		widget: 'infotext',
		sel: '.infotext'
	});

	ui.register({
		widget: 'application',
		sel: '.application'
	});

	ui.register({
		widget: 'application-accordion',
		sel: '.application-accordion'
	});

	ui.register({
		widget: 'article',
		sel: '.article'
	});

	ui.register({
		widget: 'accordion',
		sel: '.accordion'
	});

	ui.register({
		widget: 'video',
		sel: '.video'
	});

	ui.register({
		widget: 'back-to-top',
		sel: '.btn--back-to-top'
	});

	ui.register({
		widget: 'search',
		sel: '.searchresult'
	});

	ui.register({
		widget: 'search-autocomplete',
		sel: 'input.autocomplete'
	});

	ui.register({
		widget: 'short-term-hint',
		sel: '.short-term-hint-request'
	});

	ui.register({
		widget: 'faq-item',
		sel: '.faq'
	});

	// There is already a bootstrap component named 'modal'.
	// That´s why this widget is written in capital letters.
	ui.register({
		widget: 'Modal',
		sel: '.modal'
	});

	// form widgets
	ui.register({
		widget: 'form',
		sel: 'form'
	});

	ui.register({
		widget: 'form-validate',
		sel: 'form'
	});

	ui.register({
		widget: 'form-routing',
		sel: 'form.routing'
	});

	ui.register({
		widget: 'progress-bar',
		sel: '.progress-bar'
	});

	// Placeholder widget must be initialized before form components so that the form components subscribe to global form events
	ui.register({
		widget: 'placeholder',
		sel: 'form'
	});

	ui.register({
		widget: 'upload',
		sel: '.upload'
	});

	ui.register({
		widget: 'decision',
		sel: '.decision'
	});

	ui.register({
		widget: 'equation',
		sel: '.equation'
	});

	ui.register({
		widget: 'date-field',
		sel: '.date-field'
	});

	ui.register({
		widget: 'input-group',
		sel: '.input-group'
	});

	ui.register({
		widget: 'text-field',
		sel: '.text-field'
	});

	ui.register({
		widget: 'Dropdown',
		sel: '.dropdown'
	});

	ui.register({
		widget: 'conditional-container',
		sel: '.conditional-container'
	});

	ui.register({
		widget: 'tabs',
		sel: '.tabs'
	});

	ui.register({
		widget: 'smart-banner',
		sel: '.smart-banner'
	});

	ui.$(() => {
		ui.init();
	});
}(ui));
