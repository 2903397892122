/* global ui */

(function ($) {
	$.widget('ipnp.form-validate', {
		options: {
			validateFields: {}
		},

		_create() {
			const formFields = this.element[0].querySelectorAll(`:scope ${'.form-field'}`);
			if (formFields.length > 0) {
				ui.merge('ipnp.form-validate', this, {
					data: () => {
						formFields.forEach((element) => {
							const formFieldId = element.getAttribute('data-form-field-id');
							if (formFieldId) {
								const formFieldConfig = ui.widgets['ipnp.form-validate'].config[formFieldId];
								if (formFieldConfig !== undefined) {
									Object.assign(this.options.validateFields, { [formFieldId]: formFieldConfig });
								}
							}
						});
					}
				});
			}

			ui.lib.formValidate = this;

			if (this.options.validators) {
				this._addValidators();
				this._initValidation();
			}
		},

		/**
		 * Add validation rules.
		 * @private
		 */
		_addValidators() {
			$.each(this.options.validators, (validatorName, validator) => {
				if (validator.regex) {
					$.validator.addMethod(validatorName, function (value, element) {
						return this.optional(element) || new RegExp(validator.regex).test(value);
					}, $.validator.format(validator.message));
				} else if (validator.fct) {
					$.validator.addMethod(validatorName, function (value, element) {
						/* jslint evil: true */
						return this.optional(element) || eval(validator.fct);
					}, $.validator.format(validator.message));
				}
			});

			// rule for no empty value e.g. for dropdown
			$.validator.addMethod('valueNotEquals', (value, element, arg) => arg !== value, 'Select a value');
		},

		/**
		 * Setup the validation plugin.
		 * @private
		 */
		_initValidation() {
			const _this = this;
			const rules = {};
			const messages = {};

			// collect all rules and messages of all form components for validation
			Object.values(this.options.validateFields).forEach((value) => {
				Object.assign(rules, value.rules);
				Object.assign(messages, value.messages);
			});

			this.options.rules = rules;
			this.options.messages = messages;

			this.element.validate({
				debug: ui.config.log,
				rules: this.options.rules,
				messages: this.options.messages,
				ignore: '.form-field--ignore',
				onkeyup: false,
				onfocusout: false,
				errorClass: 'form-field__help form-field__help--error',
				errorElement: 'div',
				errorPlacement(error, element) {
					// set error class to jquery ui datepicker to style datepicker
					if (element.prev('input').hasClass('datepicker')) {
						$('.ui-datepicker').addClass('ui-datepicker--has-error');
					}

					$(element).closest('.form-field').find('.form-field__help--info').hide();
					error.appendTo(element.closest('.form-field'));
				},
				invalidHandler() {
					window.scrollTo(0, $(this).offset().top);
					setTimeout(() => {
						const $el = $('.form-field--has-error:first input');
						$el.focus();
					}, 100);
				},
				showErrors() {
					ui.log('Form validation errors:', this.numberOfInvalids());
					this.defaultShowErrors();
				},
				submitHandler(form) {
					if (!_this._checkButtonDependentRequire(form)) {
						return false;
					}

					if (!_this._checkFieldDependentRequire(form)) {
						return false;
					}

					// add timestamp value to hidden timestamp fields
					$(form).find('.timestamp').each((index, element) => {
						const cur = $(element);
						if (cur.length) {
							const currentDate = new Date();
							const dataFormat = cur.attr('data-format');
							if (dataFormat === 'dd.mm.yyyy') {
								// handle date-format: 'dd.mm.yyyy'
								let day = currentDate.getDate();
								let month = currentDate.getMonth() + 1;
								const year = currentDate.getFullYear();

								// add leading 0 if necessary
								day = (day < 10 ? '0' : '') + day;
								month = (month < 10 ? '0' : '') + month;
								cur.val(`${day}.${month}.${year}`);
							} else {
								// handle date-format: 'none'
								cur.val(currentDate);
							}
						}
					});

					const { submitButton } = this;
					submitButton.disabled = 'disabled';
					if ($(form).hasClass('routing')) {
						// clear the values of all form elements that have the class "form-field--ignore"
						$(form).find('.form-field--ignore').val('');
						const formRoutingId = $(form).attr('id');
						const formRoutingConfig = ui.widgets['ipnp.form-routing'].config[formRoutingId];
						$.ipnp['form-routing'](formRoutingConfig).modifyRoutingForm(form);
						$(form).find('input[name="fhs.formModelUrl"]').prop('disabled', true);
						form.submit();
					} else {
						_this._handleSubmit(form, submitButton);
					}
				},
				highlight(element) {
					$(element).closest('.form-field').addClass('form-field--has-error');
				},
				unhighlight(element) {
					$(element).closest('.form-field').find('.form-field__help--info').show();
					$(element).closest('.form-field').removeClass('form-field--has-error');
				}
			});
		},

		/**
		 * Handle the usual submit of a form.
		 *
		 * @param form The current form element.
		 * @param submitButton The button used to submit the form.
		 * @returns {boolean} 'false' if Klarna has not been validated correctly.
		 * @private
		 */
		_handleSubmit(form) {
			// clear the values of all form elements that have the class "form-field--ignore" but not for datepicker
			const $ignoreFormFields = $(form).find('.form-field--ignore');
			if (!$ignoreFormFields.hasClass('datepicker')) {
				$ignoreFormFields.val('');
			}
			const submitFormFunction = Object.getPrototypeOf(form).submit;
			submitFormFunction.call(form);
		},

		/**
		 * Check fields which can be required if a specific submit is pressed.
		 * @param form The current form element.
		 * @returns {boolean} 'true' if dependencies exist, otherwise 'false'.
		 * @private
		 */
		_checkButtonDependentRequire(form) {
			const $form = $(form);
			let submit = $form.find('button[type=submit]:focus').attr('name');
			// fallback on enter
			if (!submit) {
				submit = $form.find('button[type=submit]').first().attr('name');
			}
			// no name - no check
			if (!submit) {
				return true;
			}
			const rules = [];
			$form.find('[data-buttondependencies]').each((index, element) => {
				const cur = $(element);
				// registered to be required on this buttons
				const buttons = cur.data('buttondependencies').split(',');
				// check if submit registered to this field
				if ($.inArray(submit, buttons) !== -1) {
					// add validation rule
					cur.rules('add', { required: true });
					rules.push(cur);
				}
			});
			if ($form.valid()) {
				return true;
			}
			$.each(rules, (index, element) => {
				// reset if not valid because user can use another submit button next time
				element.rules('remove', 'required');
			});
			return false;
		},

		/**
		 * Check fields which can be required if a specific dependent field has a not-empty value.
		 * @param form The current form element.
		 * @returns {boolean} 'true' if dependencies exist, otherwise 'false'.
		 */
		_checkFieldDependentRequire(form) {
			const $form = $(form);
			const fieldsWithValues = [];
			$form
				.find('input[type=text], input[type=hidden], input[type=checkbox]:checked, input[type=radio]:checked')
				.filter('[value]:not(:disabled):not(.form-field--ignore)')
				.each((_index, element) => {
					if (element.name) {
						fieldsWithValues.push(element.name);
					}
				});
			$form
				.find('select')
				.filter(':has(option[value][value!=""]:not(:disabled):selected):not(.form-field--ignore)')
				.each((_index, element) => {
					if (element.name) {
						fieldsWithValues.push(element.name);
					}
				});
			$form
				.find('textarea')
				.filter(':not(:disabled):not(.form-field--ignore)')
				.filter((_index, element) => !!($(element).val()))
				.each((_index, element) => {
					if (element.name) {
						fieldsWithValues.push(element.name);
					}
				});

			const rules = [];
			$form.find('[data-dependencyfield]').each((_index, element) => {
				const cur = $(element);
				// registered to be required on this element
				const dependencyField = cur.data('dependencyfield');

				if (cur.val().length === 0 && $(`#${dependencyField}`).val().length === 0) {
					return true;
				}

				// check if any field with a value is registered to this field
				if ($.inArray(dependencyField, fieldsWithValues) !== -1) {
					if (cur.hasClass('form-field--ignore')) {
						cur.removeClass('form-field--ignore');
					}
					// add validation rule
					cur.rules('add', { required: true });
					rules.push(cur);
				}
			});
			if ($form.valid()) {
				return true;
			}
			$.each(rules, (_index, element) => {
				// reset if not valid because user may have changed input next time
				element.rules('remove', 'required');
			});
			return false;
		}
	});
}(ui.$));
