/* global ui */

(function ($) {
	/**
	 * Widget Constructor
	 *
	 * @constructor
	 */
	$.widget('ipnp.date-field', {
		options: {
			lang: 'de',
			dateFormat: 'dd.mm.yy',
			disallowHolidays: false,
			disallowWeekends: false,
			disallowSundays: false,
			holidaysSource: '',
			relatedField: '',
			startDate: '',
			endDate: '',
			dateIntervalMessage: '',
			minOffset: '',
			maxOffset: '',
			daterangeMessage: '',
			holidayStore: {}
		},

		/**
		 * Constructor
		 */
		_create() {
			ui.merge('ipnp.date-field', this);
			const _this = this;
			if (!ui.lib.isEditMode()) {
				this._initDatepicker();
			}

			this._validate();

			ui.sub('ui.datepicker.reinit', (e, data) => {
				data.datefields.forEach((element) => {
					const value = $(element).next().val();
					$(element).removeClass('hasDatepicker');
					$(element).attr('data-value', value);
					_this._initDatepicker($(element));
					_this._validate();
				});
			});
		},

		/**
		 * Initialize Datepicker component
		 */
		_initDatepicker($aDatefield) {
			let $dateField = $aDatefield;
			if ($dateField === undefined) {
				$dateField = this.element.find('.datepicker');
			}

			const _this = this;
			const id = $dateField.attr('id');
			const opts = {};
			const beforeShowDayFns = [];
			const updateConstraintsFns = [];

			// Set locale global
			$.datepicker.setDefaults($.datepicker.regional.de);

			// Change Datepicker start/end options
			if (this.options.startDate) {
				$.extend(opts, {
					minDate: _this._getParsedDate(_this.options.startDate)
				});
			}
			if (this.options.endDate) {
				$.extend(opts, {
					maxDate: _this._getParsedDate(_this.options.endDate)
				});
			}

			// Change Datepicker offset options
			if (this.options.minOffset) {
				if (this.options.startDate) {
					const minOffsetDate = _this._calcDateByOffset(this.options.minOffset, new Date());
					const startDate = _this._getParsedDate(this.options.startDate);
					if (minOffsetDate > startDate) {
						$.extend(opts, {
							minDate: _this.options.minOffset
						});
					}
				} else {
					$.extend(opts, {
						minDate: _this.options.minOffset
					});
				}
			}
			if (this.options.maxOffset) {
				if (this.options.endDate) {
					const maxOffsetDate = _this._calcDateByOffset(this.options.maxOffset, new Date());
					const endDate = _this._getParsedDate(this.options.endDate);
					if (maxOffsetDate < endDate) {
						$.extend(opts, {
							maxDate: _this.options.maxOffset
						});
					}
				} else {
					$.extend(opts, {
						maxDate: _this.options.maxOffset
					});
				}
			}

			this._collectHolidays(this.options);

			// Change Datepicker options depending on related datefield
			if (this.options.relatedField) {
				const rel = $(`#${id}`).closest('form').find(`#${this.options.relatedField}`);
				if (rel.length) {
					if (rel.is('.hasDatepicker') && (this.options.minOffset || this.options.maxOffset)) {
						// related field found so add a handler to update min and max of the current field when related field
						// has been changed

						const updateConstraints = function () {
							const date = _this._getDateFromField(rel);
							if (!date || Number.isNaN(date.getTime())) {
								$dateField.datepicker('option', 'disabled', true);
								return;
							}
							if (_this.options.minOffset) {
								$dateField.datepicker('option', 'minDate', _this._calcDateByOffset(_this.options.minOffset, date));
							}
							if (_this.options.maxOffset) {
								$dateField.datepicker('option', 'maxDate', _this._calcDateByOffset(_this.options.maxOffset, date));
							}
							if ($dateField.datepicker('option', 'disabled')) {
								$dateField.datepicker('option', 'disabled', false);
							}
						};

						rel.datepicker('option', 'onSelect', updateConstraints);
						ui.sub('ui.form.ready', () => {
							rel.on('change', updateConstraints);
						});

						updateConstraintsFns.push(() => {
							updateConstraints.call(rel);
						});
					}
				} else {
					// related field not available
					$dateField.closest('.form-field').addClass('form-field--has-error');
					$dateField.val('The related field is not available!');
				}
			}

			// Collect days that should not be displayed
			if (this.options.disallowHolidays) {
				beforeShowDayFns.push(_this._getHolidayInfo);
			}
			if (this.options.disallowWeekends) {
				beforeShowDayFns.push($.datepicker.noWeekends);
			}
			if (this.options.disallowSundays) {
				beforeShowDayFns.push(_this._getSundayInfo);
			}
			if (beforeShowDayFns.length !== 0) {
				$.extend(opts, {
					beforeShowDay() {
						let args = $.merge([], arguments);
						args = $.merge(args, [_this.options]);
						let result = [true, '', ''];
						for (let i = 0; i < beforeShowDayFns.length && result[0] === true; i += 1) {
							result = beforeShowDayFns[i].apply(this, args);
						}
						return result;
					}
				});
			}

			// Set prefilled value for Datepicker
			let prefilledDate;
			if ($dateField.attr('data-value')) {
				try {
					prefilledDate = $.datepicker.parseDate($.datepicker.ISO_8601, $dateField.attr('data-value'));
				} catch (e) {
					ui.log(`Cannot parse date ${$dateField.attr('data-value')}`);
				}
				$dateField.removeAttr('data-value');
			}

			// Set required attribute for hidden transfer field
			const altFieldName = $dateField.attr('name');
			let isRequired = '';
			if ($dateField.attr('required') === 'required') {
				isRequired = 'required';
			}

			// Set the format of the alt field to ensure a interpretable string for the backend
			const altField = $dateField.after(`<input type="hidden" name="${altFieldName}" ${isRequired} />`)
				.siblings('input[type=\'hidden\']');

			if ($dateField.data('buttondependencies')) {
				altField.attr('data-buttondependencies', $dateField.data('buttondependencies'));
				$dateField.removeAttr('data-buttondependencies');
			}

			// Add general options
			$.extend(opts, {
				altFormat: $.datepicker.ISO_8601,
				altField,
				dateFormat: _this.options.dateFormat,
				showButtonPanel: false,
				monthNamesShort: $.datepicker.regional.de.monthNames,
				beforeShow(input) {
					$(input).closest('.date-field').addClass('datepicker--is-open');

					if ($(input).closest('.date-field').hasClass('has-infotext')) {
						$('#ui-datepicker-div').addClass('has-infotext');
					}
				},
				onClose(dateText, inst) {
					// If "Clear" gets clicked, then really clear it.
					// Checks reset button for an existing datepicker class. Classes must be checked during a datepicker update.
					const resetBtnClasses = 'ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover';
					if ($('#ui-datepicker-div button:last-child').hasClass(resetBtnClasses)) {
						$(this).datepicker('setDate', '');
					}
					inst.input.closest('.date-field').removeClass('datepicker--is-open');

					$('#ui-datepicker-div').removeClass('has-infotext');
				}
			});

			$dateField.removeAttr('name');

			// Create datefield component
			$dateField.datepicker(opts);

			// Apply the prefilled date when necessary.
			if (prefilledDate) {
				$dateField.datepicker('setDate', $.datepicker.formatDate($dateField.datepicker('option', 'dateFormat'), prefilledDate));
				$dateField.change();
			}

			// synchronize value of datefield with hidden transfer field
			$dateField.datepicker().on('change', function () {
				if ($(this).val() === '' && !$(this).attr('data-value')) {
					$(this).next(`[name='${$(this).attr('id')}']`).attr('value', '');
				} else {
					let newDate = '';

					// check if date format is valid if date change date manually and from datepicker
					try {
						$.datepicker.parseDate(_this.options.dateFormat, $(this).val());
						newDate = $dateField.datepicker({ dateFormat: _this.options.dateFormat }).val();
					} catch (e) {
						$(this).val('');
						$(this).next(`[name='${$(this).attr('id')}']`).attr('value', '');
						ui.log(`Date is not valid ${$(this).val()}`);
					}

					// trigger change event of datepicker hidden field for placeholder replacement if datefield has new value
					if (newDate !== '') {
						ui.pub('datepicker.change', {
							date: newDate,
							name: $(this).attr('id')
						});
					}
				}
			});

			for (let i = 0; i < updateConstraintsFns.length; i += 1) {
				updateConstraintsFns[i]();
			}

			$dateField.next('input[name="undefined"]').remove();

			// open datepicker on icon click
			this.element.find('.icon').click(() => {
				$dateField.datepicker('show');
			});
		},

		/**
		 * Validate Datepicker component
		 * @private
		 */
		_validate() {
			const _this = this;
			// define validators
			// 1. daterange
			$.validator.addMethod('date_range', (value, aElement) => {
				if (value.length > 0) {
					let element = aElement;
					element = $(element).siblings('.datepicker');
					return (function () {
						const id = element.closest('.form-field').attr('id');
						const conf = ui.widgets['ipnp.date-field'].config[id];
						let rel;

						if (conf.relatedField) {
							rel = $(`#${id}`).closest('form').find(`#${conf.relatedField}`);
							if (!rel.length || !rel.is('.datepicker')) {
								// related field is required but not found or not a datepicker field
								return false;
							}
							rel = _this._getDateFromField(rel);
						} else {
							// use today when no related field is set
							rel = new Date();
						}

						const val = _this._getDateFromField(element);

						if (!rel || Number.isNaN(rel.getTime())
							|| !val || Number.isNaN(val.getTime())) {
							// rel or val is an invalid date
							return false;
						}

						return _this._isInDateRange(val, _this.options.minOffset || null, _this.options.maxOffset || null, rel);
					}()) || element.attr('disabled');
				}

				return true;
			});
			// 2. holidays
			$.validator.addMethod('date_holiday', (value, aElement) => {
				if (value.length > 0) {
					let element = aElement;
					element = $(element).siblings('.datepicker');
					return (function () {
						const id = element.closest('.form-field').attr('id');
						const conf = ui.widgets['ipnp.date-field'].config[id];

						if (_this._isDatePickerEmpty(element)) {
							return false;
						}
						/* eslint-disable no-mixed-spaces-and-tabs */
						if (!_this._isDatePickerEmpty(element) && (conf.disallowHolidays || conf.disallowWeekends
																   || conf.disallowSundays)) {
							const val = _this._getDateFromField(element);
							if (!val || Number.isNaN(val.getTime())) {
								// val is an invalid date
								return false;
							}

							return (!conf.disallowHolidays || !_this._isHoliday(val, conf))
								   && (!conf.disallowWeekends || !_this._isWeekend(val))
								   && (!conf.disallowSundays || !_this._isSunday(val));
						}
						/* eslint-enable no-mixed-spaces-and-tabs */
						return true;
					}()) || element.attr('disabled');
				}
				return true;
			});

			// 3. date interval
			$.validator.addMethod('date_interval', (value, aElement) => {
				if (value.length > 0) {
					let element = aElement;
					element = $(element).siblings('.datepicker');
					return (function () {
						const id = element.closest('.form-field').attr('id');
						const conf = ui.widgets['ipnp.date-field'].config[id];
						if (_this._isDatePickerEmpty(element)) {
							return false;
						}
						if (!_this._isDatePickerEmpty(element) && (conf.endDate || conf.startDate)) {
							const val = _this._getDateFromField(element);
							return _this._isInDateInterval(val, conf);
						}
						return true;
					}()) || element.attr('disabled');
				}
				return true;
			});
		},

		/**
		 * collect holidays
		 *
		 * @param conf configuration, is equivalent to 'this.options'
		 * @private
		 */
		_collectHolidays(conf) {
			const _this = this;
			if (conf.holidaysSource && conf.disallowHolidays) {
				if (!conf.holidayStore[conf.holidaysSource]) {
					conf.holidayStore[conf.holidaysSource] = {};
					$.ajax({
						type: 'GET',
						url: conf.holidaysSource,
						async: false,
						success(data) {
							_this._collectHolidaysDates(conf, data);
						}
					});
				}
			}
		},

		/**
		 * Validator function to disable every date in the Datepicker that's a holiday.
		 *
		 * @param date date to check for
		 * @param conf conf, is equivalent to 'this.options'
		 * @param conf dateFieldWidget, is equivalent to 'this'
		 * @private
		 */
		_getHolidayInfo(date, conf, dateFieldWidget) {
			const _this = dateFieldWidget;
			if (_this) {
				// collect holidays for frontend validation
				_this._collectHolidays(conf);
			}

			if (conf.holidayStore[conf.holidaysSource]
				&& conf.holidayStore[conf.holidaysSource][date.getFullYear()]
				&& conf.holidayStore[conf.holidaysSource][date.getFullYear()][date.getMonth()]
				&& conf.holidayStore[conf.holidaysSource][date.getFullYear()][date.getMonth()][date.getDate()]) {
				return [
					false,
					'holiday',
					conf.holidayStore[conf.holidaysSource][date.getFullYear()][date.getMonth()][date.getDate()]
				];
			}
			return [true, '', ''];
		},

		/**
		 * Collect all dates received from calendar REST API and stores it to a local array to check it after selecting a date.
		 *
		 * @param source url from REST API
		 * @param data data received from REST API
		 * @private
		 */
		_collectHolidaysDates(conf, data) {
			const source = conf.holidaysSource;
			let holidayDate;
			let dataStructure;
			switch (data['@nodeType']) {
				case 'mgnl:folder':
					// iterate directories
					data['@nodes'].forEach((folder) => this._collectHolidaysDates(conf, data[folder]));
					break;
				case 'ipnp:calendar':
					// push date
					holidayDate = new Date(data.date);
					// ignore time
					holidayDate.setHours(0, 0, 0, 0);
					dataStructure = conf.holidayStore[source];
					dataStructure[holidayDate.getFullYear()] = dataStructure[holidayDate.getFullYear()] || {};
					/* eslint-disable max-len */
					dataStructure[holidayDate.getFullYear()][holidayDate.getMonth()] =						dataStructure[holidayDate.getFullYear()][holidayDate.getMonth()] || {};
					/* eslint-enable max-len */
					dataStructure[holidayDate.getFullYear()][holidayDate.getMonth()][holidayDate.getDate()] = data.name;
					break;
				default:
					ui.log(`${this.widgetName} - Node Type ${data['@nodeType']} unknown.`);
			}
		},

		_getSundayInfo(date) {
			const day = date.getDay();
			return [(day > 0 && day < 7), ''];
		},

		_calcDateByOffset(offset, date) {
			let year = date.getFullYear();
			let month = date.getMonth();
			let day = date.getDate();
			const pattern = /([+\-]?[0-9]+)\s*(d|D|w|W|m|M|y|Y)?/g;
			let matches = pattern.exec(offset);

			while (matches) {
				switch (matches[2] || 'd') {
					case 'd':
					case 'D':
						day += parseInt(matches[1], 10);
						break;
					case 'w':
					case 'W':
						day += parseInt(matches[1], 10) * 7;
						break;
					case 'm':
					case 'M':
						month += parseInt(matches[1], 10);
						day = Math.min(day, $.datepicker._getDaysInMonth(year, month));
						break;
					case 'y':
					case 'Y':
						year += parseInt(matches[1], 10);
						day = Math.min(day, $.datepicker._getDaysInMonth(year, month));
						break;
					default:
						// Should never be reached! (Added 'default' case to fix SonarQube report.)
						ui.log(`${this.widgetName} - Operator not allowed.`);
				}
				matches = pattern.exec(offset);
			}
			return new Date(year, month, day);
		},

		_getParsedDate(date) {
			const parsedDate = $.datepicker.parseDate('yy-mm-dd', date);
			return new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate());
		},

		_getDateIntervalInfo(date, conf) {
			const year = date.getFullYear();
			const month = date.getMonth();
			const day = date.getDate();
			const givenDate = new Date(year, month, day);
			let dateAfterStartDate = true;
			if (conf.startDate) {
				const startDate = this._getParsedDate(conf.startDate);
				dateAfterStartDate = givenDate >= startDate;
			}
			let dateBeforeEndDate = true;
			if (conf.endDate) {
				const endDate = this._getParsedDate(conf.endDate);
				dateBeforeEndDate = givenDate <= endDate;
			}
			return [dateAfterStartDate && dateBeforeEndDate, '', ''];
		},

		_isHoliday(date, conf) {
			return !this._getHolidayInfo(date, conf, this)[0];
		},

		_isWeekend(date) {
			return !$.datepicker.noWeekends(date)[0];
		},

		_isSunday(date) {
			return !this._getSundayInfo(date)[0];
		},

		_isInDateRange(date, min, max, rel) {
			/* eslint-disable no-mixed-spaces-and-tabs */
			return (!min || this._calcDateByOffset(min, rel) <= this._calcDateByOffset('0d', date))
				   && (!max || this._calcDateByOffset(max, rel) >= this._calcDateByOffset('0d', date));
			/* eslint-enable no-mixed-spaces-and-tabs */
		},

		_isInDateInterval(date, conf) {
			return this._getDateIntervalInfo(date, conf)[0];
		},

		_getDateFromField(aField) {
			let field = aField;
			if (field.is('.datepicker')) {
				field = field.siblings(`input[name="${field.attr('id')}"]`);
			}

			let date = new Date(field.val());
			if (Number.isNaN(date.getTime())) {
				// maybe ie8 bug
				const dateValues = field.val().split('-');
				if (dateValues.length === 3) {
					date = new Date(dateValues[0], dateValues[1] - 1, dateValues[2]);
				}
			}
			return date;
		},

		_isDatePickerEmpty(aField) {
			let field = aField;
			if (field.is('.datepicker')) {
				field = field.siblings(`input[name="${field.attr('id')}"]`);
			}

			return field.val() === '';
		}
	});
}(ui.$));
