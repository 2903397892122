/* global ui, Tooltip */

(function ($) {
	$.widget('ipnp.infotext', {

		_create() {
			const customTooltipClass = this._getThemeClass();

			// initialize bootstrap tooltip
			// eslint-disable-next-line no-new
			new Tooltip(this.element[0], {
				customClass: customTooltipClass
			});

			// show infotext icon via javascript to hide infotext icon in no-js banner if javascript inactive
			this.element.css('display', 'inline-block');
			this.element.parent().addClass('has-infotext');
		},

		// get light mode class of infotext
		_getThemeClass() {
			let customTooltipClass = '';
			const themeLight = 'infotext--light';

			if (this.element.hasClass(themeLight)) {
				customTooltipClass += themeLight;
			}
			return customTooltipClass;
		}
	});
}(ui.$));
