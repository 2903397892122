/* global ui */

(function ($, ui) {
	$.widget('ipnp.application-accordion', {

		_create() {
			this.isInitialCall = true;
			this.scrollPositionBeforeFullscreen = 0;
			this._addStylingToIcons();
			this._handleAnchor();
			this._initializePubSubCalls();
		},

		/**
		 * Add specific styling to the selected SVG icons. Retrieve the first element of an SVG (XML) and fill it with a defined color.
		 *
		 * @private
		 */
		_addStylingToIcons() {
			const icons = document.querySelectorAll('.application-accordion-item__circle object');
			const bodies = document.querySelectorAll('.application-accordion .collapse');
			bodies.forEach(body => {
				const header = body.previousElementSibling;
				const icon = header.querySelector('.application-accordion-item__circle object');
				body.addEventListener('hide.bs.collapse', () => {
					icon.contentDocument.firstChild.style.fill = '#d40511';
				});
				body.addEventListener('show.bs.collapse', () => {
					icon.contentDocument.firstChild.style.fill = '#fff';
				});
			});
			icons.forEach((icon) => {
				if (icon != null && icon.contentDocument !== null) {
					icon.contentDocument.firstChild.style.fill = '#d40511';
				}
			});
		},

		/**
		 * Open application item with URL anchor and scroll to it.
		 *
		 * @private
		 */
		_handleAnchor() {
			if (window.location.hash.indexOf('#') === 0) {
				const id = this._createAnchorName(window.location.hash);
				const $applicationItem = $(`#${id}`);
				if ($applicationItem.length > 0) {
					const $applicationItemBody = $applicationItem.find('.accordion-item__collapse');
					if ($applicationItem.length === 1 && $applicationItemBody.length === 1) {
						// check if item is already opened
						if ($applicationItemBody.hasClass('show')) {
							// item is already about to expand -> close it
							$applicationItemBody.on('hidden.bs.collapse', () => {
								// only reopen element if it was opened initially
								if (this.isInitialCall) {
									// and show it again when finished closing
									this.expandAndScroll($applicationItem);
								}
							});
							// click to close
							$applicationItem.find('> a').click();
						} else if (this.isInitialCall) {
							// the item is not opened yet -> open it
							this.expandAndScroll($applicationItem);
						}
					} else {
						ui.log('Application Accordion registered Anchor but did not jump to Item.');
					}
				}
			}
		},

		/**
		 * Initialize all publish / subscribe callbacks for communication between application widgets and accordion.
		 *
		 * @private
		 */
		_initializePubSubCalls() {
			const _this = this;
			const $fullscreenDiv = this.element.find('#fullscreen');
			const $applicationAccordionItem = this.element.find('.application-accordion-item');
			const $previousSiblings = this.element.parent().prevAll();
			// Extend the Publish-Subscribe pattern so that React applications can request a maximized view.
			// For that, an app has to call ui.pub('application.requestFullsize', {id: 'psf-app'}).
			// If the application wants to close the maximized view it has to call
			// ui.pub('application.closeFullsize', {id: 'psf-app'}).
			ui.sub('application.requestFullsize', (e, data) => {
				const requestObject = {};
				requestObject.fullscreenElementSelector = '.application-accordion #fullscreen';

				if (data && data.id) {
					ui.log(`React app with ID [${data.id}] requested fullsize view.`);
					requestObject.id = data.id;
				} else {
					ui.log('React app requested fullsize view.');
				}

				$fullscreenDiv.empty();

				// save current scroll position
				if ($(window).scrollTop() > 0) {
					_this.scrollPositionBeforeFullscreen = $(window).scrollTop();
				}
				$('html, body').animate({
					scrollTop: 0
				}, 250);

				ui.sub('application.fullsizeRendered', () => {
					$previousSiblings.addClass('d-none');
					$applicationAccordionItem.css('height', '0');
					$applicationAccordionItem.addClass('d-none');
					ui.log('transition');
					$(this).addClass('d-none');
					$fullscreenDiv.removeClass('d-none');
					$fullscreenDiv.css('height', 'auto');
					$fullscreenDiv.show();
					ui.unsub('application.fullsizeRendered');
				});
				ui.pub('application.requestFullscreenDiv', requestObject);
			});

			ui.sub('application.closeFullsize', function (e, data) {
				if (data) {
					ui.log(`React app with ID [${data.id}] requested to close the fullsize view.`);
				} else {
					ui.log('React app requested to close the fullsize view.');
				}

				// animation
				// measure height
				$applicationAccordionItem.css('height', 'auto');
				$applicationAccordionItem.removeClass('d-none');
				$applicationAccordionItem.addClass('d-none');
				$applicationAccordionItem.css('height', '0');

				// hide fullscreen div
				$fullscreenDiv.css('height', '0');
				ReactDOM.unmountComponentAtNode($fullscreenDiv[0]);
				$fullscreenDiv.empty();
				ui.log('transition');
				$(this).addClass('d-none');
				$previousSiblings.removeClass('d-none');
				$applicationAccordionItem.removeClass('d-none');
				$applicationAccordionItem.show();
				$applicationAccordionItem.css('height', 'auto');

				// restore saved scroll position
				if (_this.scrollPositionBeforeFullscreen) {
					$('html, body').animate({
						scrollTop: _this.scrollPositionBeforeFullscreen
					}, 250);
				}
			});

			// initialises the applications
			ui.init();
		},

		_createAnchorName(aText) {
			let text = aText;
			text = decodeURIComponent(text);
			return text.replace(/ö/g, 'oe').replace(/ä/g, 'ae').replace(/ü/g, 'ue').replace(/Ö/g, 'OE')
				.replace(/Ä/g, 'AE').replace(/Ü/g, 'UE').replace(/ß/g, 'ss').replace(/[^a-zA-Z0-9_]/g, '');
		},

		/**
		 * expands an application item and scrolls to it
		 * @param $applicationItem the element with the 'anchor_*' id
		 */
		expandAndScroll($applicationItem) {
			const _this = this;
			const $applicationItemBody = $applicationItem.find('.collapse');
			const applicationId = $applicationItemBody.attr('id');
			// subscribe to bootstrap event to check when opening is finished
			$applicationItemBody.on('shown.bs.collapse', (e) => {
				if (_this.isInitialCall) {
					// check for correct id
					if (e && e.currentTarget && e.currentTarget.id && e.currentTarget.id === applicationId) {
						window.scrollTo({ top: $applicationItem.offset().top });
					}
				}
				_this.isInitialCall = false;
			});
			$applicationItem.find('> a').click();
		}
	});
}(ui.$, ui));
