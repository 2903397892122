/* global ui */

(function ($) {
	$.widget('ipnp.accordion', {

		_create() {
			// stop propagation if an accordion inside another collapse component, e.g. circle icon expandable
			this.element.on('show.bs.collapse', (event) => {
				event.stopPropagation();
				$(event.target).closest('.accordion-item').addClass('accordion-item--show');

				// initialize videos in faq
				const faqVideos = document.querySelectorAll('.faq .video');
				if (faqVideos.length > 0) {
					faqVideos.forEach((video) => {
						const videoScript = video.querySelector('script.video__config');
						eval(videoScript.textContent);
					});
					ui.widgets.video.init();
				}
			});

			this.element.on('hide.bs.collapse', (event) => {
				event.stopPropagation();
				$(event.target).closest('.accordion-item').removeClass('accordion-item--show');
			});

			this._openByAnchor();
		},

		/**
		 * check if an anchor in url - open accordion element - jump to anchor
		 */
		_openByAnchor() {
			const url = new URL(window.location);
			const urlHash = url.hash;

			if (urlHash && urlHash !== '#' && urlHash !== '#undefined') {
				const accordionItem = $(`.accordion-item[name="${encodeURIComponent(urlHash.substr(1))}"]`);
				if (accordionItem.length > 0) {
					accordionItem.find('.accordion-item__collapse').collapse('toggle');
				}
			}
		}
	});
}(ui.$, window));
