/* global ui */

(function ($) {
	$.widget('ipnp.decision', {
		options: {
			type: null,
			dependsOn: null,
			trueValue: null,
			falseValue: null,
			exec: null,
			lowerBound: null,
			upperBound: null,
			ranges: [],
			minChars: null,
			onKeyPressUrl: null
		},

		_create() {
			ui.merge('ipnp.decision', this);

			if (this.options.exec) {
				this._handleField();
			}
		},

		_handleField() {
			const _this = this;
			const $dependsOn = $(`#${this.options.dependsOn}`);
			const handler = this.options.type;
			if (!$dependsOn.length) {
				return;
			}
			if (!handler) {
				return;
			}
			if (this.options.type === 'matchFileEntry') {
				this._matchFileEntry($dependsOn);
			} else {
				$dependsOn.change(() => {
					let value;
					if ($dependsOn.attr('type') === 'checkbox' && !$dependsOn.is(':checked')) {
						value = false;
					} else {
						value = $dependsOn.val();
						// Special handling for radio buttons needed.
						if (!value) {
							value = $dependsOn.find('input[type="radio"]:checked').val();
						}
					}
					switch (handler) {
						case 'emptyCheck':
							_this._emptyCheck(value);
							break;
						case 'matchRanges':
							_this._matchRanges(value);
							break;
						default:
						// Should never be reached! (Added 'default' case to fix SonarQube report.)
					}
				});
			}
		},

		_emptyCheck(value) {
			this._setVal(!value);
		},

		_matchRanges(value) {
			// eslint-disable-next-line no-param-reassign
			value = parseFloat(value);
			let evalResult = false;
			if (!Number.isNaN(value)) {
				// eslint-disable-next-line no-plusplus
				for (let i = 0; i < this.options.ranges.length; i++) {
					const range = this.options.ranges[i];
					if ((parseInt(range.lowerBound, 10) <= value) && (parseInt(range.upperBound, 10) >= value)) {
						evalResult = true;
						this.options.trueValue = range.trueValue;
						break;
					}
				}
			}
			this._setVal(evalResult);
		},

		_setVal(evalResult) {
			let isDisabled = true;
			const $decisionInput = this.element.find('input[type="hidden"]');
			if (evalResult === false) {
				if (this.options.falseValue) {
					$decisionInput.val(this.options.falseValue).trigger('change');
					isDisabled = false;
				}
			} else if (evalResult === true) {
				$decisionInput.val(this.options.trueValue).trigger('change');
				isDisabled = false;
			}
			$decisionInput.attr('disabled', isDisabled ? 'disabled' : null);
		},

		// Check referenced field value against file and write result in decision field.
		_matchFileEntry(input) {
			const _this = this;
			const $decisionInput = this.element.find('input[type="hidden"]');
			input.on('keyup', () => {
				const value = input.val();
				if (value.length >= _this.options.minChars) {
					const params = new URLSearchParams();
					const encodedValue = encodeURIComponent(value);
					params.append('data', encodedValue);

					fetch(_this.options.onKeyPressUrl + '~' + params.toString() + '~', {
						method: 'GET',
						headers: {
							'Content-Type': 'text/plain'
						}
					})
						.then(response => {
							if (!response.ok) {
								return Promise.reject(response);
							}
							return response.json();
						})
						.then(function (data) {
							if (typeof data.validationresult === 'string') {
								$decisionInput.val(data.validationresult).trigger('change');
							}
						})
						.catch(error => {
							if (typeof error.json === 'function') {
								ui.log('Error from requested URL:', error);
							} else {
								ui.log('Fetch error:', error);
							}
						});
				} else {
					$decisionInput.val('').trigger('change');
				}
			});
		}
	});
}(ui.$));
