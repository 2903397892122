/* global ui */

(function ($) {
	$.widget('ipnp.window', {

		options: {
			scrollDelay: 100,
			resizeDelay: 200,
			breakpoints: {
				xs: `(max-width:${ui.lib.breakpointLimits.sm - 1}px)`,
				sm: `(min-width:${ui.lib.breakpointLimits.sm}px) and (max-width: ${ui.lib.breakpointLimits.md - 1}px)`,
				md: `(min-width:${ui.lib.breakpointLimits.md}px) and (max-width: ${ui.lib.breakpointLimits.lg - 1}px)`,
				lg: `(min-width:${ui.lib.breakpointLimits.lg}px)`
			}
		},

		_create() {
			this._watchBreakpointChange();
			this._watchResize();
			this._watchScroll();

			// prefill form with url parameter only once!!
			$.ipnp.form().prefill();
		},

		/**
		 * Add listener if viewport changed
		 */
		_watchBreakpointChange() {
			const _this = this;

			// all breakpoints
			const mediaQueryList = [
				// xs
				window.matchMedia(_this.options.breakpoints.xs),
				// sm
				window.matchMedia(_this.options.breakpoints.sm),
				// md
				window.matchMedia(_this.options.breakpoints.md),
				// lg
				window.matchMedia(_this.options.breakpoints.lg)
			];

			// window.matchMedia listener is triggered when its breakpoint is changed.
			// Therefore only two listeners are necessary to have a better performance.

			// listener is triggered if viewport XS changed to SM or from SM to XS
			mediaQueryList[0].addEventListener('change', () => {
				if (mediaQueryList[0].matches) {
					_this._setBreakpointAfterChange('xs');
				}

				if (mediaQueryList[1].matches) {
					_this._setBreakpointAfterChange('sm');
				}
			});

			// listener is triggered if viewport MD changed to SM or viewport MD changed to LG
			mediaQueryList[2].addEventListener('change', () => {
				if (mediaQueryList[1].matches) {
					_this._setBreakpointAfterChange('sm');
				}

				if (mediaQueryList[2].matches) {
					_this._setBreakpointAfterChange('md');
				}

				if (mediaQueryList[3].matches) {
					_this._setBreakpointAfterChange('lg');
				}
			});
		},

		/**
		 * Publish event if viewport changed
		 */
		_setBreakpointAfterChange(newBreakpoint) {
			ui.lib.currentBreakpoint = newBreakpoint;
			ui.pub('ui.breakpointChanged', ui.lib.currentBreakpoint);
		},

		/**
		 * Observes the resizing of the window and triggers a throttled resize event and an event when the resizing begins.
		 */
		_watchResize() {
			const _this = this;
			let resizeDebounceTimeout;
			let triggerResizeStart = true;
			let customResizeEvent;

			if (ui.lib.isMobileDevice()) {
				customResizeEvent = 'orientationchange';
			} else {
				customResizeEvent = 'resize';
			}

			$(window).on(customResizeEvent, () => {
				ui.pub('ui.windowresize');

				if (triggerResizeStart) {
					triggerResizeStart = false;
					_this.resizeStart();
				}

				clearTimeout(resizeDebounceTimeout);
				resizeDebounceTimeout = setTimeout(() => {
					triggerResizeStart = true;
				}, _this.options.resizeDelay);
			});
		},

		resizeStart() {
			ui.pub('ui.windowresizestart');
		},

		/**
		 * Publish throttled scroll event
		 */
		_watchScroll() {
			const _this = this;
			let throttlePause;

			document.addEventListener('scroll', () => {
				if (throttlePause) {
					return;
				}

				throttlePause = true;
				setTimeout(() => {
					ui.pub('ui.scroll');
					throttlePause = false;
				}, _this.options.scrollDelay);
			});
		}
	});
}(ui.$));
